import React from 'react'
import Snippet from './Snippet'
import FetchInterceptor from '../IntegrationMocks/FetchInterceptor'
import DealerinspireVdpMock from '../IntegrationMocks/dealerinspire/DealerinspireVdpMock'
import DealerDotComMock from '../IntegrationMocks/dealerdotcom/DealerDotComMock'
import {DEALER_INTEGRATION_SCRIPTS, PAGE_TYPES} from '../constants'
import {useParamsContext} from '../hooks/useParams'

const DealerIntegration = () => {
  const {paramsInitial, vehicles, configJson} = useParamsContext()
  const {integration, pageType} = paramsInitial
  let Component = null

  switch (integration) {
    case DEALER_INTEGRATION_SCRIPTS.DEALER_INSPIRE:
      switch (pageType) {
        case PAGE_TYPES.VDP:
          Component = (
            <>
              <FetchInterceptor />
              <DealerinspireVdpMock vehicle={vehicles[0]} />
              <Snippet />
            </>
          )
          break
        case PAGE_TYPES.SRP:
          Component = (
            <>
              <FetchInterceptor />
              <Snippet />
            </>
          )
          break
      }

      break
    case DEALER_INTEGRATION_SCRIPTS.DEALER_DOT_COM:
      Component = <DealerDotComMock {...{paramsInitial, vehicles, configJson}} />
      break
    case DEALER_INTEGRATION_SCRIPTS.DEALER_EPROCESS:
    case DEALER_INTEGRATION_SCRIPTS.DEALER_CAR_SEARCH:
    case DEALER_INTEGRATION_SCRIPTS.DEALER_ON:
    case DEALER_INTEGRATION_SCRIPTS.TEAM_VELOCITY:
    case DEALER_INTEGRATION_SCRIPTS.MOTIVE:
    case DEALER_INTEGRATION_SCRIPTS.NABTHAT:
      Component = (
        <>
          <FetchInterceptor />
          <Snippet />
        </>
      )
      break

    default:
      if (integration) {
        console.error('A2Z DR: Test Page: No Dealer Integration.')
      }
  }

  return Component
}

export default DealerIntegration
