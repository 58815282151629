import React from 'react'
import PropTypes from 'prop-types'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faExclamationTriangle} from '@fortawesome/free-solid-svg-icons'
import {Popover} from '@headlessui/react'
import classNames from 'classnames'

const ConfigNotFound = ({paramsInitial, configJson}) => {
  // Applying regular expression matching and breaking up the components of the "config.json" URL into named variables.
  const [, urlStart, , clientUuid, dealershipId, lastDir, urlEnd] = configJson.url.match(
    /^(.*?\/\/(.*?\/){3})(.*?)\/(.*?)\/(.*?)\/(.*)$/
  )

  const tooltips = {
    clientUuid: {
      id: 'tooltip-clientUuid',
      label: 'Client UUID',
      offsetLeft: -20,
      className: 'tw-text-green-500',
      value: clientUuid,
    },
    dealershipId: {
      id: 'tooltip-dealershipId',
      label: 'Dealership ID',
      offsetLeft: -25,
      className: 'tw-text-indigo-500',
      value: dealershipId,
    },
    lastDir: {
      id: 'tooltip-lastDir',
      label: paramsInitial.useLegacySnippet ? 'Integration' : 'Dealership Domain',
      offsetLeft: 20,
      className: 'tw-text-pink-500',
      value: lastDir,
    },
  }

  return (
    <>
      <div className="tw-mt-4 tw-mb-12">
        <div
          className="tw-grid tw-grid-flow-col tw-gap-4"
          style={{gridTemplateColumns: 'min-content 1fr'}}
        >
          <div className="tw-row-span-2">
            <FontAwesomeIcon icon={faExclamationTriangle} className="tw-text-yellow-400" />
          </div>
          <div>
            <span className="tw-font-semibold">Config not found.</span>&nbsp; Please confirm the
            config.json file and the directories exist at the following location:
          </div>
        </div>
        <div className="tw-text-center" style={{fontSize: '.8rem'}}>
          <a
            href={configJson.url}
            target="_blank"
            rel="noreferrer"
            className="tw-text-gray-900 hover:tw-text-gray-700 hover:tw-bg-gray-200 tw-inline-block tw-py-2 tw-px-4 tw--mx-10 tw-leading-6 tw-transition tw-duration-350"
          >
            {urlStart}
            {Object.values(tooltips).map(({id, className, value, label, offsetLeft}) => (
              <React.Fragment key={id}>
                <Popover className="tw-inline-block tw-relative">
                  <span
                    className={`${className} tw-inline-block hover:tw-bg-white tw-px-1 tw-transition tw-duration-350`}
                    id={id}
                  >
                    {value}
                  </span>
                  <Popover.Panel
                    static
                    className="tw-absolute tw-left-1/2 tw--translate-x-1/2 tw-bg-white tw-rounded-lg tw-shadow-md tw-whitespace-nowrap tw-py-1 tw-px-2"
                    style={{marginLeft: offsetLeft}}
                  >
                    <span className={classNames('tw-font-semibold', className)}>{label}</span>
                  </Popover.Panel>
                </Popover>
                /
              </React.Fragment>
            ))}
            {urlEnd}
          </a>
        </div>
      </div>
    </>
  )
}

ConfigNotFound.propTypes = {
  paramsInitial: PropTypes.shape({
    useLegacySnippet: PropTypes.bool,
  }),
  configJson: PropTypes.shape({
    url: PropTypes.string,
    exists: PropTypes.bool,
  }),
}

export default ConfigNotFound
