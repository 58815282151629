import PropTypes from 'prop-types'
import {getKeyValuePairsFromObjectByKey as getKeyValuesByKey} from './helpers'

export const PAGE_TYPES = {
  SRP: 'srp',
  VDP: 'vdp',
}

export const DEALER_INTEGRATIONS = {
  DEALER_INSPIRE: {
    name: 'Dealer Inspire',
    script: 'dealerinspire',
    directory: 'dealerinspire',
  },
  DEALER_DOT_COM: {
    name: 'Dealer.com',
    script: 'dealer.com',
    directory: 'dealerdotcom',
  },
  DEALER_EPROCESS: {
    name: 'Dealer eProcess',
    script: 'dealer-eprocess',
    directory: 'dealer-eprocess',
  },
  DEALER_CAR_SEARCH: {
    name: 'Dealer Car Search',
    script: 'dealer-car-search',
    directory: 'dealer-car-search',
  },
  DEALER_ON: {
    name: 'DealerOn',
    script: 'dealeron',
    directory: 'dealeron',
  },
  NABTHAT: {
    name: 'Nabthat',
    script: 'nabthat',
    directory: 'nabthat',
  },
  TEAM_VELOCITY: {
    name: 'Team Velocity',
    script: 'team-velocity',
    directory: 'team-velocity',
  },
  MOTIVE: {
    name: 'Motive',
    script: 'motive',
    directory: 'motive',
  },
}

export const DEALER_INTEGRATION_NAMES = getKeyValuesByKey(DEALER_INTEGRATIONS, 'name')
export const DEALER_INTEGRATION_SCRIPTS = getKeyValuesByKey(DEALER_INTEGRATIONS, 'script')
export const DEALER_INTEGRATION_DIRECTORIES = getKeyValuesByKey(DEALER_INTEGRATIONS, 'directory')

export const VEHICLE_CONDITIONS = {
  NEW: 'new',
  USED: 'used',
}

export const VEHICLE_CONDITIONS_PROP_TYPE = PropTypes.oneOf(Object.values(VEHICLE_CONDITIONS))

export const PARAM_HIGHLIGHTS = {
  UNDEFINED: 'tw-bg-gradient-to-r tw-from-yellow-100 tw-via-yellow-50 tw-to-yellow-50',
  OVERRIDDEN: 'tw-bg-gradient-to-r tw-from-green-100 tw-via-green-50 tw-to-green-50',
}
