import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import {VEHICLE_CONDITIONS_PROP_TYPE} from '../../constants'

const DealerEProcessCta = ({children, vehicle, ctaLabel, isDisabled, className, containerRef}) => {
  const vehicleConditionFirstLetterUpperCase =
    vehicle.condition.charAt(0).toUpperCase() + vehicle.condition.slice(1)

  const Cta = ({hidden}) => (
    <a
      className={classNames('digital-retailing', className, {'tw-hidden': hidden})}
      href="#"
      tabIndex="-1"
      data-vin={vehicle.vin}
      data-stock="8973"
      data-type={vehicleConditionFirstLetterUpperCase}
      data-year="2022"
      data-make="Honda"
      data-model="Accord"
      data-trim="EX-L"
      data-msrp="35500"
      data-mileage="583"
      onClick={(e) => e.preventDefault}
      data-disabled={isDisabled}
    >
      {ctaLabel}
    </a>
  )

  return (
    <div ref={containerRef}>
      <Cta />

      {/*
       *   A hidden button simulates situations where multiple buttons
       *   exist (mobile & desktop versions, etc) in the dom at the same time.
       *   Multiple buttons with the same VIN will be considered the same when
       *   determining SRP or VDP.
       *   See: /static/dealer-eprocess.js & https://a2zsync.atlassian.net/browse/AZ-22260
       */}

      <Cta hidden={true} />
      {children}
    </div>
  )
}

DealerEProcessCta.propTypes = {
  children: PropTypes.node,
  vehicle: PropTypes.shape({
    vin: PropTypes.string,
    condition: VEHICLE_CONDITIONS_PROP_TYPE,
  }),
  ctaLabel: PropTypes.string,
  isDisabled: PropTypes.bool,
  className: PropTypes.string,
  containerRef: PropTypes.shape({}),
}

export default DealerEProcessCta
