import {createContext, useContext, useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {DEALER_INTEGRATIONS, DEALER_INTEGRATION_SCRIPTS, PAGE_TYPES} from '../constants'
import {deleteEmptyPropertiesInObjectRecursively} from '../helpers'

const paramsDefault = {}
export const ParamsContext = createContext(paramsDefault)
export const useParamsContext = () => useContext(ParamsContext)

const parseBoolType = (param) => {
  if (param === undefined) {
    return param
  }

  return typeof param === 'string' ? !['false', 'no', '0'].includes(param?.toLowerCase()) : !!param
}

const getConfigPath = ({config, lastDir}) =>
  `${window.location.origin}/digital-retail/clients/${config.clientUuid}/${config.dealershipId}/${lastDir}/config.json`

const fetchConfig = async ({config, setConfigJson, lastDir}) => {
  const configPath = getConfigPath({config, lastDir})
  let exists = false

  // On local, Gatsby returns a status "200 Success" response regardless of whether the "config.json" file exists or not.
  try {
    const response = await fetch(configPath)
    const json = await response.json()
    exists = typeof json === 'object' && !!Object.keys(json).length

    return exists ? json : false
  } catch (e) {
    console.error('A2Z DR config not found:', configPath)
  } finally {
    setConfigJson({
      url: configPath,
      exists,
    })
  }
}

const getConfig = async ({overrides, setConfigJson}) => {
  const lastDir = overrides.useLegacySnippet
    ? Object.values(DEALER_INTEGRATIONS).find((item) => item.script === overrides.integration)
        ?.directory
    : new URL(overrides.sourceDomain).hostname

  const config = await fetchConfig({
    config: overrides,
    setConfigJson,
    lastDir,
  })

  return config || false
}

const useParams = ({urlParamsObject}) => {
  const [paramsInitial, setParamsInitial] = useState({})
  const [params, setParams] = useState({})
  const [paramsConfig, setParamsConfig] = useState({})
  const [hasParamsChanged, setHasParamsChanged] = useState(false)
  const [configJson, setConfigJson] = useState({
    url: '',
    exists: false,
  })

  const handleParamsUpdate = ({name, value}) => {
    setParams((prevState) => {
      const newState = {
        ...prevState,
      }

      if (name.includes('.')) {
        const [property, subProperty] = name.split('.')

        newState[property] = {
          ...newState[property],
          [subProperty]: value,
        }

        return newState
      }

      newState[name] = value

      return newState
    })
  }

  const getParamValue = ({params, name}) => {
    if (name.includes('.')) {
      const [property, subProperty] = name.split('.')

      return params[property]?.[subProperty]
    }

    return params[name]
  }

  const isParamInConfig = ({name}) =>
    typeof getParamValue({params: paramsConfig, name}) !== 'undefined'

  const isParamOverridden = ({name, value}) => {
    const paramValue = value === undefined ? getParamValue({params, name}) : value

    return isParamInConfig({name})
      ? paramValue.toString() !== getParamValue({params: paramsConfig, name}).toString()
      : true
  }

  const getSourceDomain = () => {
    if (urlParamsObject.sourceDomain) {
      return urlParamsObject.sourceDomain
    }

    return window.location.origin
  }

  // Load config
  useEffect(() => {
    const overridesBase = {
      // Settings
      pageType: urlParamsObject.pageType ?? PAGE_TYPES.VDP,
      useLegacySnippet: parseBoolType(urlParamsObject.useLegacySnippet) ?? true,
      // Config
      isPriceLocked: parseBoolType(urlParamsObject.isPriceLocked),
      useCache: parseBoolType(urlParamsObject.useCache),
      enableA2zDr: parseBoolType(urlParamsObject.enableA2zDr) ?? null,
      enableSearchPage: parseBoolType(urlParamsObject.enableSearchPage) ?? null,
      scopeVehicleType: parseBoolType(urlParamsObject.scopeVehicleType)
        ? urlParamsObject.scopeVehicleType
        : '',
      apiUrl: urlParamsObject.apiUrl,
      apiToken: urlParamsObject.apiToken,
      sourceDomain: getSourceDomain(),
      theme: urlParamsObject.theme ?? '',
      vdpAutoOpen: parseBoolType(urlParamsObject.vdpAutoOpen) ?? null,
      // Dealership
      clientUuid: urlParamsObject.clientUuid,
      dealershipId: urlParamsObject.dealershipId,
      dealershipName: urlParamsObject.dealershipName,
      dealershipEmail: urlParamsObject.dealershipEmail,
      dealershipPhone: urlParamsObject.dealershipPhone,
      // Analytics
      gtmId: urlParamsObject.gtmId,
      measurementIds: urlParamsObject.measurementIds?.split(','),
      enableShiftDigital: parseBoolType(urlParamsObject.enableShiftDigital) ?? true,
      // Call to Action (CTA)
      ctaClassifier: urlParamsObject.ctaClassifier,
      ctaLabel: urlParamsObject.ctaLabel,
      ctaLabelForPriceLocked: urlParamsObject.ctaLabelForPriceLocked,
      // Registration Form
      registrationForm: {
        phoneRequired: parseBoolType(urlParamsObject.registrationForm?.phoneRequired) ?? null,
      },
    }

    overridesBase.integration = Object.values(DEALER_INTEGRATION_SCRIPTS).includes(
      urlParamsObject.integration
    )
      ? urlParamsObject.integration
      : ''

    // Delete all properties whose value is "null" or "undefined".
    const overrides = deleteEmptyPropertiesInObjectRecursively(overridesBase)

    ;(async () => {
      const config = await getConfig({overrides, setConfigJson})

      if (!config) {
        setParams(overrides)
        setParamsInitial(overrides)

        return
      }

      config.gtmId = config?.analytics?.gtmId ?? ''
      config.measurementIds = config?.analytics?.measurementIds ?? []
      delete config.analytics

      setParamsConfig(config)

      overrides.enableA2zDr = overrides.enableA2zDr ?? config.enableA2zDr ?? true
      overrides.enableSearchPage = overrides.enableSearchPage ?? config.enableSearchPage ?? true

      const paramsCombined = {
        ...config,
        ...overrides,
      }

      setParams(paramsCombined)
      setParamsInitial(paramsCombined)
    })()
  }, [])

  useEffect(() => {
    setHasParamsChanged(JSON.stringify(params) !== JSON.stringify(paramsInitial))
  }, [params, paramsInitial])

  return {
    params,
    paramsInitial,
    hasParamsChanged,
    handleParamsUpdate,
    isParamInConfig,
    isParamOverridden,
    configJson,
  }
}

useParams.propTypes = {
  urlParamsObject: PropTypes.shape({}),
}

export default useParams
