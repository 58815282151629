import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const DealerInspireVdpCta = ({children, isDisabled, ctaLabel, className, containerRef}) => {
  return (
    <div ref={containerRef}>
      <button
        type="button"
        className={classNames('digital-retailing', className)}
        disabled={isDisabled}
      >
        {ctaLabel}
      </button>
      {children}
    </div>
  )
}

DealerInspireVdpCta.propTypes = {
  children: PropTypes.node,
  isDisabled: PropTypes.bool,
  ctaLabel: PropTypes.string,
  className: PropTypes.string,
  containerRef: PropTypes.shape({}),
}

export default DealerInspireVdpCta
