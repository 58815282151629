import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import {useVehiclesContext} from '../../hooks/useVehicles'
import DummyCta from '../DummyCta'
import {VEHICLE_CONDITIONS_PROP_TYPE} from '../../constants'

const DealerInspireSrpCta = ({
  children,
  vehicle,
  ctaLabel,
  isDisabled,
  className,
  containerRef,
}) => {
  const {ctaCount, vehiclesInitial} = useVehiclesContext()

  /**
   * If this is a new Vehicle Card that was just added using the "Add Vehicle" button,
   * display the Dummy CTA until the user clicks on the "Generate Test Page" button.
   */
  if (!vehiclesInitial.map((vehicleInitial) => vehicleInitial.uuid).includes(vehicle.uuid)) {
    return (
      <>
        <div className="tw-grid tw-gap-1">
          {Array(ctaCount)
            .fill()
            .map((_, index) => (
              <DummyCta
                {...{key: `cta-${vehicle.uuid}-${index.toString()}`, ctaLabel, className}}
              />
            ))}
        </div>
        {children}
      </>
    )
  }

  return (
    <>
      <div
        ref={containerRef}
        data-vehicle={JSON.stringify({...vehicle, type: vehicle.condition})}
        className="tw-grid tw-gap-1"
      >
        {Array(ctaCount)
          .fill()
          .map((_, index) => (
            <button
              key={`cta-${vehicle.uuid}-${index.toString()}`}
              type="button"
              className={classNames('digital-retailing', className)}
              disabled={isDisabled}
            >
              {ctaLabel}
            </button>
          ))}
      </div>
      {children}
    </>
  )
}

DealerInspireSrpCta.propTypes = {
  children: PropTypes.node,
  vehicle: PropTypes.shape({
    vin: PropTypes.string,
    uuid: PropTypes.string,
    condition: VEHICLE_CONDITIONS_PROP_TYPE,
  }),
  ctaLabel: PropTypes.string,
  isDisabled: PropTypes.bool,
  className: PropTypes.string,
  containerRef: PropTypes.shape({}),
}

export default DealerInspireSrpCta
