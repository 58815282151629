import {useRef} from 'react'
import {useParamsContext} from '../hooks/useParams'

const Snippet = () => {
  const {paramsInitial} = useParamsContext()
  const isLoaded = useRef(false)
  if (isLoaded.current) {
    return null
  }
  isLoaded.current = true
  const product = 'digital-retail'

  const {clientUuid, dealershipId, integration, useLegacySnippet, sourceDomain} = paramsInitial

  if (useLegacySnippet) {
    // eslint-disable-next-line func-names,no-unused-vars
    ;(function (f, p, s, c, d, i) {
      const [h] = f.getElementsByTagName('head')
      const e = f.createElement('script')
      e.async = false

      // eslint-disable-next-line camelcase,no-unused-vars
      window.A2Z_digitalRetailConfig = {
        clientUuid: c,
        dealershipId: d,
        frame: f,
        product: p,
        integration: i,
        sourceDomain: s,
      }

      e.src = `${s}/${p}/embed.js`
      h.insertAdjacentElement('beforeend', e)
    })(document, product, sourceDomain, clientUuid, dealershipId, integration)
  } else {
    // eslint-disable-next-line func-names,no-unused-vars
    ;(function (f, p, c, d) {
      const [h] = f.getElementsByTagName('head')
      const e = f.createElement('script')
      e.async = false

      // eslint-disable-next-line camelcase,no-unused-vars
      window.A2Z_digitalRetailConfig = {
        clientUuid: c,
        dealershipId: d,
        frame: f,
        product: p,
      }

      // Note: the `a2zsync` subdomain is not used in local and QA envs
      e.src = `${sourceDomain}/${p}/embed.js`
      h.insertAdjacentElement('beforeend', e)
    })(document, product, clientUuid, dealershipId)
  }

  return null
}

export default Snippet
