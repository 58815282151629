import React from 'react'
import PopoverHover from 'Src/components/Popovers/PopoverHover'
import {useParamsContext} from '../hooks/useParams'

const NotesSection = () => {
  const {
    params: {ctaLabel, ctaLabelForPriceLocked},
    configJson,
  } = useParamsContext()

  const popoverContent = (
    <>
      <div className="tw-text-base tw-font-medium tw-mb-1">“Call to Action” Buttons</div>
      <div>
        <p className="tw-mb-2">
          CTA buttons inside each vehicle card will appear in one of two ways, based on your
          configuation:
        </p>
        <button
          type="button"
          className="tw-bg-blue-500 tw-text-gray-100 tw-rounded-md tw-px-3 tw-py-1 tw-w-full"
          disabled
        >
          {ctaLabel}
        </button>
        <div className="tw-my-1 tw-text-center">&mdash; OR &mdash;</div>
        <button
          type="button"
          className="tw-bg-blue-500 tw-text-gray-100 tw-rounded-md tw-px-3 tw-py-1 tw-w-full"
          disabled
        >
          {ctaLabelForPriceLocked}
        </button>
      </div>
    </>
  )

  return (
    <>
      <div className="tw-pt-8">
        <h2 className="tw-text-xl tw-font-medium tw-mb-4">About this page:</h2>
        <p className="tw-mb-4">
          This is the universal page for testing DigitalLane in various mocked environments
          (dealership integration/config, page type, one or more used/new vehicles).
        </p>
        <p className="tw-mb-4">
          The configuration of this page is based on values from the following sources:
        </p>
        <ol className="tw-list-decimal tw-ml-8 tw-mb-8">
          <li className="tw-mb-2">
            <a href={configJson.url} target="_blank" rel="noreferrer" className="tw-underline">
              config.json
            </a>{' '}
            &mdash; Default values are loaded from the contents of this file.
          </li>
          <li>
            This page &mdash; Any values you change on this page will override the defaults and will
            be reflected in the query parameters once you click the{' '}
            <span className="tw-whitespace-nowrap">“Generate Test Page”</span> button.
          </li>
        </ol>
        <h2 className="tw-text-xl tw-font-medium tw-mb-4">How to use this page:</h2>
        <div className="tw-mb-4">
          Each{' '}
          <PopoverHover className="tw-inline-block" content={popoverContent}>
            <span className="tw-border-b tw-border-dotted tw-border-black tw-cursor-help">
              call-to-action button
            </span>
          </PopoverHover>{' '}
          inside the Vehicle Cards above is configured to work based on the query parameters
          provided on initial page load.
        </div>
        <p className="tw-mb-4">
          If you modify the configuration or vehicle information on this page{' '}
          <span className="tw-italic">after</span> page load, you have to click the{' '}
          <span className="tw-whitespace-nowrap">“Generate Test Page”</span> button for this new
          configuration to take effect and be reflected in the call-to-action buttons.
        </p>
      </div>
    </>
  )
}

export default NotesSection
