import React from 'react'
import GenerateButton from './GenerateButton'

const ButtonsSection = () => {
  return (
    <section className="tw-pt-6">
      <GenerateButton />
    </section>
  )
}

ButtonsSection.propTypes = {}

export default ButtonsSection
