import React from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faLock} from '@fortawesome/free-solid-svg-icons/faLock'
import {faLockOpen} from '@fortawesome/free-solid-svg-icons/faLockOpen'
import Box from './BoxCollapsible/Box'
import Row from './BoxCollapsible/Row'
import TextInput from './BoxCollapsible/TextInput'
import {useParamsContext} from '../hooks/useParams'
import {useRowHighlighter} from '../hooks/useRowHighlighter'

const CtaBox = () => {
  const {params, handleParamsUpdate} = useParamsContext()

  return (
    <Box
      heading={
        <>
          Call to Action &nbsp;<span className="tw-font-normal">(CTA)</span>
        </>
      }
    >
      <Row
        label="Classifier"
        labelAlternate="ctaClassifier"
        highlight={useRowHighlighter({name: 'ctaClassifier'})}
      >
        <TextInput
          name="ctaClassifier"
          value={params.ctaClassifier}
          handleUpdate={handleParamsUpdate}
        />
      </Row>
      <Row
        label={
          <>
            <span>Label</span>
            <FontAwesomeIcon icon={faLockOpen} className="tw-ml-2 tw-opacity-60" />
          </>
        }
        labelAlternate="ctaLabel"
        highlight={useRowHighlighter({name: 'ctaLabel'})}
      >
        <TextInput name="ctaLabel" value={params.ctaLabel} handleUpdate={handleParamsUpdate} />
      </Row>
      <Row
        label={
          <>
            <span>Label</span>
            <FontAwesomeIcon icon={faLock} className="tw-ml-2 tw-opacity-60 tw-mr-1" />
          </>
        }
        labelAlternate="ctaLabelForPriceLocked"
        highlight={useRowHighlighter({name: 'ctaLabelForPriceLocked'})}
      >
        <TextInput
          name="ctaLabelForPriceLocked"
          value={params.ctaLabelForPriceLocked}
          handleUpdate={handleParamsUpdate}
        />
      </Row>
    </Box>
  )
}

export default CtaBox
