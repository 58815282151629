import React from 'react'
import PropTypes from 'prop-types'

const ErrorsSection = ({errors}) => {
  if (!errors.length) {
    return null
  }

  return (
    <section className="tw-pt-6 tw-pb-2">
      <div>
        <p>The following errors prevent the page from being generated:</p>
        {errors.map((error, index) => {
          return (
            <div key={`error-${index.toString()}`} className="tw-text-red-600">
              {error}
            </div>
          )
        })}
      </div>
    </section>
  )
}

ErrorsSection.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.string),
}

export default ErrorsSection
