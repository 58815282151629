import React from 'react'
import classNames from 'classnames'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faPlus, faMinus} from '@fortawesome/free-solid-svg-icons'
import {DEALER_INTEGRATION_SCRIPTS, PAGE_TYPES} from 'Src/test-page/constants'
import {useVehiclesContext} from 'Src/test-page/hooks/useVehicles'
import IconPopoverWrapper from 'Src/test-page/components/IconPopoverWrapper'

const CTA_COUNT = {
  MAX: 4,
  MIN: 1,
}

const CtaCountButtons = () => {
  const {paramsInitial, handleCtaCount, ctaCount} = useVehiclesContext()
  const {DEALER_INSPIRE, NABTHAT} = DEALER_INTEGRATION_SCRIPTS

  const showCtaCountButtons =
    paramsInitial.pageType === PAGE_TYPES.SRP &&
    [DEALER_INSPIRE, NABTHAT].includes(paramsInitial.integration)

  if (!showCtaCountButtons) {
    return null
  }

  return (
    <div className="tw-ml-auto tw-flex tw-items-center tw-gap-2">
      <IconPopoverWrapper
        {...{
          popoverPlacement: 'bottom',
          popoverOffset: [0, 12],
        }}
      >
        Add multiple CTAs per Vehicle Card to simulate dynamically-injected CTAs.
      </IconPopoverWrapper>
      <div className="tw-my-3">CTAs:</div>
      <div className="tw-flex tw-gap-1">
        {[
          [faPlus, true],
          [faMinus, false],
        ].map(([faIcon, step]) => {
          const disabled = ctaCount === (step ? CTA_COUNT.MAX : CTA_COUNT.MIN)

          return (
            <button
              key={`cta-button-${step.toString()}`}
              type="button"
              className={classNames(
                'tw-rounded tw-bg-blue-500 tw-px-3 tw-py-1 tw-text-white disabled:tw-cursor-not-allowed disabled:tw-opacity-50',
                {'hover:tw-bg-blue-700': !disabled}
              )}
              onClick={() => handleCtaCount(step)}
              disabled={disabled}
            >
              <FontAwesomeIcon icon={faIcon} />
            </button>
          )
        })}
      </div>
    </div>
  )
}

export default CtaCountButtons
