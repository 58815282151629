import React, {useEffect, useState} from 'react'
import classNames from 'classnames'
import PopoverHover from 'Src/components/Popovers/PopoverHover'
import {useButtonsContext} from '../../hooks/useButtons'
import GenerateButtonHelpers from './GenerateButtonHelpers'
import GenerateButtonPopover from './GenerateButtonPopover'

const GenerateButton = () => {
  const {hasParamsOrVehiclesChanged, hasErrors} = useButtonsContext()
  const isGenerateButtonEnabled = hasParamsOrVehiclesChanged && !hasErrors
  const [isButtonEnabled, setIsButtonEnabled] = useState(isGenerateButtonEnabled)
  const {buttonText, updateButton, redirect} = GenerateButtonHelpers({setIsButtonEnabled})

  useEffect(() => {
    setIsButtonEnabled(isGenerateButtonEnabled)
  }, [isGenerateButtonEnabled])

  const handleButtonClick = (event) => {
    updateButton(event)
    redirect()
  }

  const Component = () => (
    <div
      className={classNames('tw-inline-block', {
        'tw-cursor-not-allowed tw-select-none': !isButtonEnabled,
      })}
    >
      <button
        type="submit"
        className="tw-w-52 tw-rounded tw-bg-blue-500 tw-px-4 tw-py-2 tw-text-white enabled:hover:tw-bg-blue-700 disabled:tw-pointer-events-none disabled:tw-opacity-50"
        onClick={handleButtonClick}
        disabled={!isButtonEnabled}
      >
        {buttonText}
      </button>
    </div>
  )

  return (
    <PopoverHover
      content={GenerateButtonPopover()}
      className="tw-inline-block"
      buttonElement="div"
      panelClassName="tw-w-60 tw-text-sm"
      options={{placement: 'bottom', modifiers: [{name: 'offset', options: {offset: [0, 8]}}]}}
    >
      <Component />
    </PopoverHover>
  )
}

export default GenerateButton
