import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import DummyCta from '../DummyCta'
import {useVehiclesContext} from 'Src/test-page/hooks/useVehicles'
import {VEHICLE_CONDITIONS_PROP_TYPE} from 'Src/test-page/constants'

const MotiveCta = ({children, vehicle, ctaLabel, isDisabled, className, containerRef}) => {
  const {ctaCount, vehiclesInitial} = useVehiclesContext()

  /**
   * If this is a new Vehicle Card that was just added using the "Add Vehicle" button,
   * display the Dummy CTA until the user clicks on the "Generate Test Page" button.
   */
  if (!vehiclesInitial.map((vehicleInitial) => vehicleInitial.uuid).includes(vehicle.uuid)) {
    return (
      <>
        <div className="tw-grid tw-gap-1">
          {Array(ctaCount)
            .fill()
            .map((_, index) => (
              <DummyCta
                {...{key: `cta-${vehicle.uuid}-${index.toString()}`, ctaLabel, className}}
              />
            ))}
        </div>
        {children}
      </>
    )
  }

  return (
    <div ref={containerRef} className="tw-grid tw-gap-1">
      {Array(ctaCount)
        .fill()
        .map((_, index) => (
          <a
            key={`cta-${vehicle.uuid}-${index.toString()}`}
            className={classNames('digital-retailing', className)}
            href="#"
            tabIndex="-1"
            data-vin={vehicle.vin}
            data-type={vehicle.condition}
            onClick={(e) => e.preventDefault}
            data-disabled={isDisabled}
          >
            {ctaLabel}
          </a>
        ))}
      {children}
    </div>
  )
}

MotiveCta.propTypes = {
  children: PropTypes.node,
  vehicle: PropTypes.shape({
    vin: PropTypes.string,
    uuid: PropTypes.string,
    condition: VEHICLE_CONDITIONS_PROP_TYPE,
  }),
  ctaLabel: PropTypes.string,
  isDisabled: PropTypes.bool,
  className: PropTypes.string,
  containerRef: PropTypes.shape({}),
}

export default MotiveCta
