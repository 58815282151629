import React from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faPlus} from '@fortawesome/free-solid-svg-icons'
import {useVehiclesContext} from '../../hooks/useVehicles'

const AddVehicleButton = () => {
  const {handleAddVehicle} = useVehiclesContext()

  return (
    <button
      type="button"
      className="tw-bg-blue-500 hover:tw-bg-blue-700 tw-text-white tw-py-1 tw-px-3 tw-rounded"
      onMouseUp={() => {
        handleAddVehicle()
      }}
    >
      <FontAwesomeIcon icon={faPlus} /> Add Vehicle
    </button>
  )
}

export default AddVehicleButton
