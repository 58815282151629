import React from 'react'
import Box from './BoxCollapsible/Box'
import {useParamsContext} from '../hooks/useParams'
import {PARAM_HIGHLIGHTS} from '../constants'

const style = {boxShadow: 'inset 0 2px 2px #fff, inset 0 -2px 2px #fff'}

const LegendBox = () => {
  const {configJson} = useParamsContext()

  return (
    <Box heading="Legend">
      <div
        className={`tw-px-4 tw-py-3 tw-text-gray-600 tw-leading-snug ${PARAM_HIGHLIGHTS.UNDEFINED}`}
        style={style}
      >
        Indicates a variable not defined in your{' '}
        <a href={configJson.url} target="_blank" rel="noreferrer" className="tw-underline">
          config.json
        </a>
      </div>
      <div
        className={`tw-px-4 tw-py-3 tw-text-gray-600 tw-leading-snug ${PARAM_HIGHLIGHTS.OVERRIDDEN}`}
        style={style}
      >
        Indicates an overridden value versus your{' '}
        <a href={configJson.url} target="_blank" rel="noreferrer" className="tw-underline">
          config.json
        </a>
      </div>
    </Box>
  )
}

export default LegendBox
