import {useState} from 'react'

const useErrors = () => {
  const [errors, setErrors] = useState([])

  const addErrors = (errorsToAdd = []) => {
    setErrors((prevState) => {
      const errorsNew = [...new Set([...prevState, ...errorsToAdd])].sort()
      return errorsNew
    })
  }

  const removeErrors = (errorsToRemove = []) => {
    const errorsNew = [...errors]

    errorsToRemove.forEach((error) => {
      const index = errorsNew.indexOf(error)
      if (index !== -1) {
        errorsNew.splice(index, 1)
      }
    })

    setErrors(errorsNew)
  }

  return {errors, addErrors, removeErrors}
}

export default useErrors
