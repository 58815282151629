import React from 'react'
import {useButtonsContext} from '../../hooks/useButtons'

const GenerateButtonPopover = () => {
  const {hasParamsOrVehiclesChanged, hasErrors} = useButtonsContext()

  if (hasErrors) {
    return <>Please fix the errors above to generate a new test page.</>
  }

  if (!hasParamsOrVehiclesChanged) {
    return (
      <>
        You’re already viewing the most <span className="tw-whitespace-nowrap">up-to-date</span>{' '}
        test page. Modify the configuration or vehicle information to generate a new test page.
      </>
    )
  }

  return null
}

export default GenerateButtonPopover
