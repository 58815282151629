import {useEffect} from 'react'
import PropTypes from 'prop-types'
import {VEHICLE_CONDITIONS_PROP_TYPE} from '../../constants'

const DealerinspireVdpMock = ({vehicle}) => {
  // eslint-disable-next-line camelcase
  window.inventory_localization = {
    type: 'new',
    year: '2021',
    make: 'Subaru',
    model: 'Impreza',
    stock_number: 'stocknum',
    mileage: '5',
    trim: 'Limited',
    msrp: '19852',
    vehicle: {
      ...vehicle,
      type: vehicle.condition,
    },
  }

  useEffect(() => {
    /**
     * The logic inside this return statement will only run when this component unmounts.
     * This mimics the "componentWillUnmount" lifecycle method in React's class components.
     */
    return () => {
      delete window.inventory_localization
    }
  }, [])

  return null
}

DealerinspireVdpMock.propTypes = {
  vehicle: PropTypes.shape({
    condition: VEHICLE_CONDITIONS_PROP_TYPE,
  }),
}

export default DealerinspireVdpMock
