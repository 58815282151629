import React from 'react'
import ButtonGroup from 'Src/test-page/components/BoxCollapsible/ButtonGroup'
import ShiftDigitalQuestion from 'Src/test-page/components/Settings/ShiftDigitalQuestion'
import Box from './BoxCollapsible/Box'
import Row from './BoxCollapsible/Row'
import TextInput from './BoxCollapsible/TextInput'
import {useParamsContext} from '../hooks/useParams'
import {useRowHighlighter} from '../hooks/useRowHighlighter'

const AnalyticsBox = () => {
  const {params, handleParamsUpdate} = useParamsContext()
  const measurementIds = params?.measurementIds
    ? Array.isArray(params.measurementIds)
      ? params.measurementIds?.join(',')
      : params.measurementIds
    : ''

  return (
    <Box heading="Analytics">
      <Row
        label="GTM ID"
        labelAlternate="analytics.gtmId"
        highlight={useRowHighlighter({name: 'gtmId'})}
      >
        <TextInput name="gtmId" value={params?.gtmId ?? ''} handleUpdate={handleParamsUpdate} />
      </Row>
      <Row
        label="Measurement IDs"
        labelAlternate="analytics.measurementIds"
        highlight={useRowHighlighter({name: 'measurementIds'})}
      >
        <TextInput
          name="measurementIds"
          value={measurementIds}
          handleUpdate={({name, value}) => {
            // Remove whitespaces from individual array elements.
            const valuesArray = value.split(',').map((element) => element.trim())
            handleParamsUpdate({
              name,
              value: valuesArray,
            })
          }}
        />
      </Row>
      <Row label="Shift Digital">
        <div className="tw-flex tw-gap-4 tw-items-center">
          <ButtonGroup
            name="enableShiftDigital"
            handleParamsUpdate={handleParamsUpdate}
            buttons={[
              {
                label: 'On',
                value: true,
                isActive: params.enableShiftDigital,
              },
              {
                label: 'Off',
                value: false,
                isActive: !params.enableShiftDigital,
              },
            ]}
          />
          <ShiftDigitalQuestion />
        </div>
      </Row>
    </Box>
  )
}

export default AnalyticsBox
