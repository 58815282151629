import React from 'react'
import Box from './BoxCollapsible/Box'
import Row from './BoxCollapsible/Row'
import TextInput from './BoxCollapsible/TextInput'
import TrailingSlashWarning from './BoxCollapsible/TrailingSlashWarning'
import {useParamsContext} from '../hooks/useParams'
import {useRowHighlighter} from '../hooks/useRowHighlighter'

const DealershipBox = () => {
  const {params, handleParamsUpdate, configJson} = useParamsContext()

  return (
    <Box heading="Dealership">
      {params.useLegacySnippet ? null : (
        <Row label="Base URL">
          <TextInput
            name="sourceDomain"
            value={params.sourceDomain}
            handleUpdate={handleParamsUpdate}
          />
          {params.sourceDomain?.substr(-1) === '/' ? <TrailingSlashWarning /> : null}
        </Row>
      )}
      <Row label="Client UUID">
        <TextInput
          name="clientUuid"
          handleUpdate={handleParamsUpdate}
          value={params?.clientUuid ?? ''}
        />
      </Row>
      <Row label="ID">
        <TextInput
          name="dealershipId"
          value={params?.dealershipId ?? ''}
          handleUpdate={handleParamsUpdate}
        />
      </Row>
      {configJson.exists ? (
        <>
          <Row
            label="Name"
            labelAlternate="dealershipName"
            highlight={useRowHighlighter({name: 'dealershipName'})}
          >
            <TextInput
              name="dealershipName"
              value={params.dealershipName}
              handleUpdate={handleParamsUpdate}
            />
          </Row>
          <Row
            label="Phone"
            labelAlternate="dealershipPhone"
            highlight={useRowHighlighter({name: 'dealershipPhone'})}
          >
            <TextInput
              name="dealershipPhone"
              value={params.dealershipPhone}
              handleUpdate={handleParamsUpdate}
            />
          </Row>
          <Row
            label="Email"
            labelAlternate="dealershipEmail"
            highlight={useRowHighlighter({name: 'dealershipEmail'})}
          >
            <TextInput
              name="dealershipEmail"
              value={params.dealershipEmail}
              handleUpdate={handleParamsUpdate}
            />
          </Row>
        </>
      ) : null}
    </Box>
  )
}

export default DealershipBox
