import React from 'react'
import {useParamsContext} from '../../hooks/useParams'
import IconPopoverWrapper from '../IconPopoverWrapper'

const PriceLockedQuestion = () => {
  const {
    params: {ctaLabel, ctaLabelForPriceLocked},
  } = useParamsContext()

  return (
    <IconPopoverWrapper {...{panelClassName: '', popoverOffset: [50, 14]}}>
      <div className="tw-divide-y tw-divide-gray-100">
        <div className="tw-pb-2">
          When <span className="tw-font-semibold tw-text-black">Price Locked</span> is{' '}
          <span className="tw-font-semibold tw-text-green-700">Yes</span>:
        </div>
        <div className="tw-flex tw-flex-col tw-gap-1 tw-pb-3 tw-pt-2">
          <div className="tw-text-sm">
            <span className="tw-font-semibold">New</span> vehicles will show:
          </div>
          <div>
            <button
              type="button"
              className="tw-w-full tw-rounded-md tw-bg-blue-500 tw-px-3 tw-py-1 tw-text-gray-100"
              disabled
            >
              {ctaLabelForPriceLocked}
            </button>
          </div>
        </div>
        <div className="tw-flex tw-flex-col tw-gap-1 tw-py-2">
          <div className="tw-text-sm">
            <span className="tw-font-semibold">Used</span> vehicles will show:
          </div>
          <div>
            <button
              type="button"
              className="tw-w-full tw-rounded-md tw-bg-blue-500 tw-px-3 tw-py-1 tw-text-gray-100"
              disabled
            >
              {ctaLabel}
            </button>
          </div>
        </div>
      </div>
    </IconPopoverWrapper>
  )
}

export default PriceLockedQuestion
