import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import styles from './RadioGroup.module.scss'

const RadioGroup = ({name, className, handleParamsUpdate, options}) => {
  const handleInputClick = (event) => {
    if (event.target.checked) {
      handleParamsUpdate({
        name,
        value: event.target.value,
      })
    }
  }

  return (
    <div className={classNames('tw-max-w-lg', className)}>
      {options.map((option, index) => {
        const inputId = `${name}-${index}`

        return (
          <div key={inputId}>
            <label
              data-testid="radiogroup-label"
              htmlFor={inputId}
              className={classNames('tw-inline-flex tw-items-center tw-cursor-pointer', {
                'tw-opacity-50 tw-cursor-not-allowed': option?.isDisabled,
              })}
            >
              <input
                data-testid="radiogroup-input"
                id={inputId}
                name={name}
                type="radio"
                className={classNames(
                  'tw-h-4 tw-w-4 tw-cursor-pointer !tw-text-indigo-600 focus:!tw-ring-indigo-500',
                  {
                    '!tw-border-gray-300': !option.isActive,
                    '!tw-border-indigo-600': option.isActive,
                  }
                )}
                value={option.value}
                checked={option.isActive}
                onChange={handleInputClick}
                disabled={option?.isDisabled || false}
              />
              {option.labelAlternate ? (
                <div className={classNames(styles.radioLabel, 'tw-ml-3')}>
                  <div className="tw-relative">
                    <span>{option.label}</span>
                    <span
                      className={classNames(
                        styles.radioLabelAlternate,
                        'tw-font-mono tw-absolute tw--top-px tw-z-10 tw-bg-gray-100 tw-border tw-border-gray-200 tw-px-1.5 tw-rounded tw-text-gray-800 tw-text-xs tw-whitespace-nowrap'
                      )}
                    >
                      {option.labelAlternate}
                    </span>
                  </div>
                </div>
              ) : (
                <span className="tw-ml-3 tw-block">{option.label}</span>
              )}
            </label>
          </div>
        )
      })}
    </div>
  )
}

RadioGroup.propTypes = {
  name: PropTypes.string,
  className: PropTypes.string,
  handleParamsUpdate: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
      labelAlternate: PropTypes.string,
      value: PropTypes.string,
      isActive: PropTypes.bool,
      handleInputClick: PropTypes.func,
    })
  ),
}

export default RadioGroup
