import React from 'react'
import IconPopoverWrapper from '../IconPopoverWrapper'

const ShiftDigitalQuestion = () => (
  <IconPopoverWrapper {...{panelClassName: 'tw-w-80'}}>
    <div className="tw-font-medium tw-mb-1">Shift Digital</div>
    <div className="tw-text-xs tw-text-gray-600 tw-leading-4">
      <p className="tw-mb-1">
        Toggle adding Shift Digital within the parent window while loading the static embed script.
      </p>
    </div>
  </IconPopoverWrapper>
)

export default ShiftDigitalQuestion
