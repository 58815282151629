import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './Row.module.scss'

const Row = ({children, label, labelAlternate, highlight}) => {
  return (
    <div
      data-testid="row"
      className={classNames('tw-flex tw-gap-4 tw-items-center tw-py-1.5', highlight, {
        [styles.rowBoxShadow]: highlight,
      })}
    >
      <div className="tw-flex-initial tw-w-1/4 lg:tw-w-1/3 tw-text-right tw-leading-none tw-text-gray-600">
        {labelAlternate ? (
          <div
            className={classNames(
              styles.rowLabel,
              'tw-cursor-alias tw-inline-block tw-py-2 tw-pl-4 tw--ml-4'
            )}
          >
            <div className="tw-relative">
              <span
                className={classNames(
                  styles.rowLabelAlternate,
                  'tw-font-mono tw-absolute tw--right-1 tw--top-2 tw-z-10 tw-bg-gray-100 tw-border tw-border-gray-200 tw-p-1 tw-rounded tw-text-gray-800 tw-text-sm'
                )}
                data-testid="row-label-alternate"
              >
                {labelAlternate}
              </span>
              <span className={styles.rowLabelOriginal} data-testid="row-label">
                {label}
              </span>
            </div>
          </div>
        ) : (
          <>{label}</>
        )}
      </div>
      <div className="tw-flex-1" data-testid="row-children">
        {children}
      </div>
    </div>
  )
}

Row.propTypes = {
  label: PropTypes.node.isRequired,
  labelAlternate: PropTypes.string,
  children: PropTypes.node,
  highlight: PropTypes.string,
}

export default Row
