import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import {useVehiclesContext} from '../../hooks/useVehicles'
import DummyCta from '../DummyCta'

const DealerDotComVdpCta = ({children, index, ctaLabel, isDisabled, className}) => {
  const {hasParamsOrVehiclesChanged} = useVehiclesContext()

  /**
   * If this is not the first Vehicle Card and this is the VDP page -OR-
   * page config has changed, show the Dummy CTA.
   */
  const showDummyCta = index || hasParamsOrVehiclesChanged

  return (
    <>
      {showDummyCta ? <DummyCta {...{ctaLabel, className}} /> : null}
      {/* Becuase the DIV element below is manipulated by Dealer.com's API outside of this React component,
      instead of unmounting it when "showDummyCta" is true, we simply hide-and-show this element using classNames. */}
      <div
        data-widget-name="ws-vehicle-ctas"
        data-disabled={isDisabled}
        className={classNames('vehicle-ctas tw-rounded-md', {'tw-hidden': showDummyCta})}
      >
        {children}
      </div>
    </>
  )
}

DealerDotComVdpCta.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number,
  ctaLabel: PropTypes.string,
  isDisabled: PropTypes.bool,
  className: PropTypes.string,
}

export default DealerDotComVdpCta
