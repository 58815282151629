import React from 'react'
import {useParamsContext} from '../../hooks/useParams'
import IconPopoverWrapper from '../IconPopoverWrapper'

const VDPAutoOpenQuestion = () => {
  const {
    params: {vdpAutoOpen},
  } = useParamsContext()

  return (
    <IconPopoverWrapper>
      Should Digital Retail automatically open on page load on the VDP without the user explicitly
      clicking the CTA?
    </IconPopoverWrapper>
  )
}

export default VDPAutoOpenQuestion
