import React, {useRef} from 'react'
import PropTypes from 'prop-types'
import VehicleCard from './VehicleCard'
import VehicleNone from './VehicleNone'
import {useVehiclesContext} from '../../hooks/useVehicles'

const VehiclesGrid = ({AddVehicleButton}) => {
  const {vehicles} = useVehiclesContext()
  const vehiclesLengthPrevious = useRef()
  const focusOnLastVin = vehiclesLengthPrevious.current < vehicles.length
  vehiclesLengthPrevious.current = vehicles.length

  return (
    <div className="tw-flex tw-gap-5 tw-flex-wrap">
      {vehicles.length ? (
        vehicles.map((vehicle, index) => {
          /**
           * If the user just clicked the "Add Vehicle" button, focus their cursor
           * into the VIN text field inside the last VehicleCard component.
           */
          const focusOnVin = focusOnLastVin && index === vehicles.length - 1

          return (
            <VehicleCard
              {...{
                key: `vehicle-${vehicle.uuid}`,
                index,
                vehicle,
                focusOnVin,
              }}
            />
          )
        })
      ) : (
        <VehicleNone {...{AddVehicleButton}} />
      )}
    </div>
  )
}

VehiclesGrid.propTypes = {
  AddVehicleButton: PropTypes.func,
}

export default VehiclesGrid
