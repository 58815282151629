import React from 'react'
import PropTypes from 'prop-types'

const TextInput = ({name, value, placeholder, handleUpdate}) => {
  return (
    <input
      data-testid="textinput"
      type="text"
      {...{placeholder, value}}
      className="focus:tw-ring-indigo-500 focus:tw-border-indigo-500 tw-block tw-w-full tw-border-gray-300 tw-rounded-md tw-py-1.5"
      onChange={(event) => {
        handleUpdate({name, value: event.target.value})
      }}
    />
  )
}

TextInput.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  handleUpdate: PropTypes.func,
}

export default TextInput
