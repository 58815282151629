import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import {VEHICLE_CONDITIONS_PROP_TYPE} from '../../constants'

const DealerOnCta = ({children, vehicle, ctaLabel, isDisabled, className, containerRef}) => {
  const vehicleConditionFirstLetterUpperCase = vehicle.condition.charAt(0).toUpperCase()

  return (
    <div ref={containerRef}>
      <a
        className={classNames('digital-retailing', className)}
        href="#"
        tabIndex="-1"
        data-vin={vehicle.vin}
        data-type={vehicleConditionFirstLetterUpperCase}
        onClick={(e) => e.preventDefault}
        data-disabled={isDisabled}
      >
        {ctaLabel}
      </a>
      {children}
    </div>
  )
}

DealerOnCta.propTypes = {
  children: PropTypes.node,
  vehicle: PropTypes.shape({
    vin: PropTypes.string,
    condition: VEHICLE_CONDITIONS_PROP_TYPE,
  }),
  ctaLabel: PropTypes.string,
  isDisabled: PropTypes.bool,
  className: PropTypes.string,
  containerRef: PropTypes.shape({}),
}

export default DealerOnCta
