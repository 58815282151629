import React from 'react'
import PropTypes from 'prop-types'
import {useVehiclesContext} from '../../hooks/useVehicles'
import DummyCta from '../DummyCta'

const DealerDotComSrpCta = ({children, vehicle, ctaLabel, isDisabled, className}) => {
  const {vehiclesInitial} = useVehiclesContext()

  /**
   * If this is a new Vehicle Card that was just added using the "Add Vehicle" button,
   * display the Dummy CTA until the user clicks on the "Generate Test Page" button.
   */
  if (!vehiclesInitial.map((vehicleInitial) => vehicleInitial.uuid).includes(vehicle.uuid)) {
    return <DummyCta {...{ctaLabel, className}} />
  }

  return (
    <div
      data-disabled={isDisabled}
      className="vehicle-ctas tw-rounded-md"
      data-widget-name="vehicle-ctas"
      data-location="vehicle-ctas"
      data-uuid={vehicle.uuid}
    >
      {children}
    </div>
  )
}

DealerDotComSrpCta.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number,
  vehicle: PropTypes.shape({
    uuid: PropTypes.string,
  }),
  isDisabled: PropTypes.bool,
  ctaLabel: PropTypes.string,
  className: PropTypes.string,
}

export default DealerDotComSrpCta
