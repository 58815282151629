import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const DummyCta = ({ctaLabel, className}) => {
  return (
    <button type="button" className={classNames(className)} disabled>
      {ctaLabel}
    </button>
  )
}

DummyCta.propTypes = {
  ctaLabel: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  className: PropTypes.string,
}

export default DummyCta
