import React, {useEffect, useRef} from 'react'
import PropTypes from 'prop-types'
import './DealerDotComMock.css'
import {PAGE_TYPES} from '../../constants'
import {paramsToConfig} from '../../helpers'

const addScript = (path) => {
  const script = document.createElement('script')
  script.src = path
  script.async = false
  document.body.appendChild(script)
}

/**
 * Dealer.com versions its "ddc-js-api" code.
 * This reflects the version used in production rooftops and needs to be kept up to date.
 */
const ddcJsApiVersion = '1.6.10'

/**
 * MutationObserver to modify the elements relevant to the CTAs.
 * https://mdn.io/MutationObserver
 *
 * Because Dealer.com's API asynchronously injects the CTAs onto the page for us, we must wait and look
 * out for the CTAs to become available in the DOM.  Once the conditons inside the MutationObserver's
 * callback find the elements relevant to the CTAs, our logic makes the necessary updates to the styling
 * (by adding and removing classNames) to match the theme of our Test Page.
 */
const attachMutationObserver = () => {
  const containerClassName = 'vehicle-ctas'
  const targetNodes = document.querySelectorAll(`.${containerClassName}`)

  const callback = (mutationList) => {
    const divElementClassesToToggle = 'tw-pointer-events-none tw-opacity-50'.split(' ')

    mutationList.forEach((mutation) => {
      if (
        mutation.type === 'attributes' &&
        mutation.target.classList.contains(containerClassName)
      ) {
        // DIV element wrapping the CTA.
        const divElement = mutation.target.querySelector('[data-web-api-id="a2zsync"]')

        if (divElement) {
          if (mutation.target.getAttribute('data-disabled') === 'true') {
            divElement.classList.add('tw-cursor-not-allowed')
            divElement.firstChild.classList.add(...divElementClassesToToggle)
          } else {
            divElement.classList.remove('tw-cursor-not-allowed')
            divElement.firstChild.classList.remove(...divElementClassesToToggle)
          }
        }
      }

      if (mutation.type === 'childList') {
        const element = mutation.addedNodes?.[0]

        if (!element) {
          return
        }

        // DIV element wrapping the CTA.
        if (element.getAttribute('data-web-api-id') === 'a2zsync') {
          // Remove classes received from Dealer.com but break our Test Page styling.
          element.classList.remove('mb-3')
        }

        // CTA Anchor tag.
        if (element.getAttribute('data-modified-by') === 'a2zsync') {
          // Add classes to match Test Page styling.
          element.classList.add(
            ...'tw-bg-blue-500 tw-text-gray-100 tw-rounded-md tw-px-2 tw-py-1 tw-w-full hover:tw-bg-blue-700 tw-block tw-text-center hover:tw-text-white'.split(
              ' '
            )
          )
          // Remove classes received from Dealer.com but break our Test Page styling.
          element.classList.remove(...'btn btn-block btn-default btn-sm'.split(' '))
        }
      }
    })
  }

  if (targetNodes.length) {
    targetNodes.forEach((targetNode) => {
      const observer = new MutationObserver(callback)
      observer.observe(targetNode, {attributes: true, childList: true, subtree: true})
    })
  }
}

const injectCode = ({paramsInitial, vehicles, configJson}) => {
  const ts = Date.now()
  const sourceDomain = 'https://www.roimotors.com'
  const configJsonUrlObject = new URL(configJson.url)

  const originalFetch = window.fetch

  window.fetch = (url, options) => {
    /**
     * Intercepting the request and spoofing the response from
     * https://www.roimotors.com/api/ipp/config/services?integrationType=api,api_compatible
     * with our own responseBody.
     */
    if (url.startsWith('/api/ipp/config/services')) {
      return Promise.resolve({
        ok: true,
        json: () => ({
          a2zsync: {
            url: null,
            fields: {
              clientUuid: paramsInitial?.clientUuid || 'CLIENT-UUID-MISSING',
              excludedPages: '',
              dealershipId: paramsInitial?.dealershipId || '1',
              enabledDesktop: true,
              enabledSeamless: true,
              scriptDomain: new URL(paramsInitial.sourceDomain).host,
              enabled: true,
              integrationUrl: 'https://{scriptDomain}/digital-retail/integrations/dealer.com.js',
            },
            contentMappings: {},
            wiseIntegration: false,
            debugMode: false,
            supplementaryData: {
              siteProperties: {},
              siteMaps: {},
            },
          },
        }),
      })
    }

    /**
     * Intercepting the request and spoofing the response from
     * ${sourceDomain}/digital-retail/clients/${clientUuid}/${dealershipId}/dealerdotcom/config.json
     * with our overridden params object.
     */
    const urlObject = new URL(url)

    if (
      `${urlObject.pathname}${urlObject.search}` ===
      `${configJsonUrlObject.pathname}${configJsonUrlObject.search}`
    ) {
      return Promise.resolve({
        ok: true,
        json: () => paramsToConfig({params: paramsInitial}),
      })
    }

    if (urlObject.pathname.match(/\/config\.json$/)) {
      return Promise.resolve({
        ok: false,
      })
    }

    return originalFetch(url, options)
  }

  window.DDC = window.DDC || {}
  window.DDC.Flags = {}
  window.DDC.Flags.useDdcDataLayer = false
  window.DDC.integrationTrackerData = [
    {
      integrationId: 'a2zsync',
      integrationType: 'api',
      integrationCategory: 'Digital Retailing',
      placesInventoryContent: true,
    },
  ]

  window.DDC.siteSettings = {
    siteId: 'roimotors',
    pageAlias: 'AUTO_FLEET_USED_DETAILS',
    accountId: 'roimotors',
    defaultDomain: sourceDomain,
  }
  window.DDC.siteProperties = {
    drEnable: true,
  }
  window.DDC.hasFeature = {}

  window.DDC.getSiteProperties = () => {
    return window.DDC.siteProperties
  }

  window.DDC.getSiteProperty = (propName) => {
    let propValue

    if (propName in window.DDC.siteProperties) {
      propValue = window.DDC.siteProperties[propName]
    }

    return propValue
  }

  window.DDC.digitalRetailing = DDC.digitalRetailing || {}
  window.DDC.moduleJSCacheBust = ts
  // eslint-disable-next-line padding-line-between-statements
  ;((DDC) => {
    DDC = DDC || {}
    DDC.dataLayer = DDC.dataLayer || {}
    DDC.dataLayer.client = {}
    DDC.dataLayer.user = {}
    DDC.dataLayer.date = {}
    DDC.dataLayer.site = DDC.dataLayer.site || {}
    DDC.dataLayer.site.features = {
      drEnable: true,
      digitalRetailing: {
        enabled: true,
      },
    }
    DDC.dataLayer.site.franchises = ['roimotors']
    DDC.dataLayer.site.siteInfo = {
      dealerId: 'roimotors',
      domain: 'roimotors.com',
    }
    DDC.dataLayer.page = DDC.dataLayer.page || {}
    DDC.dataLayer.page.pageInfo = {
      pageId: 'v9_INVENTORY_DETAIL_RESPONSIVE_AUTO_FLEET_USED_V1_1',
      pageName: 'AUTO_FLEET_USED_DETAILS',
      params: '',
      pageWidgets: ['ws-vehicle-ctas'],
      isHomepage: false,
      isVlp: paramsInitial?.pageType === PAGE_TYPES.SRP,
      isVdp: paramsInitial?.pageType === PAGE_TYPES.VDP,
      isShowroomOverview: false,
      hasLeadForm: false,
      isConfirm: false,
    }
    DDC.dataLayer.page.attributes = {
      layoutType: 'mobile',
    }
    DDC.dataLayer.dealership = {
      address1: '',
      address2: '',
      city: '',
      country: '',
      dealerCode: [],
      dealershipName: '',
      dealerContactFirstName: '',
      dealerContactLastName: '',
      postalCode: '',
      stateProvince: '',
      phone1: '',
      email: '',
      website: '',
    }
    DDC.dataLayer.vehicles = vehicles.map((vehicle) => ({
      accountId: 'roimotors',
      askingPrice: '12345',
      autodataCaId: 'null',
      dealerCodes: {},
      address: {},
      inventoryType: vehicle.condition,
      uuid: vehicle.uuid,
      vin: vehicle.vin,
    }))

    if (paramsInitial?.pageType === PAGE_TYPES.SRP) {
      DDC.InvData = {
        inventory: {
          accounts: {},
          incentives: {},
          inventory: DDC.dataLayer.vehicles.map((vehicle) => ({
            ...vehicle,
            // "pricing" property required by Dealer.com SRP script on the "window.DDC.InvData.inventory.inventory" object.
            pricing: {
              dPrice: [
                {
                  isFinalPrice: true,
                  label: 'CMA Price',
                  type: 'TOTAL',
                  typeClass: 'internetPrice',
                  value: '$12,345',
                },
              ],
              ePriceStatus: 'NON_APPLICABLE',
              retailPrice: '$12,345',
              vehicle: {
                category: 'AUTO',
              },
            },
          })),
          pageInfo: {
            totalCount: vehicles.length,
          },
        },
        registerToggleListingView: () => {},
      }
    }
  })(window.DDC)

  /**
   * Every 0.25 seconds, we look for: <script data-web-api-id="a2zsync">
   * Once it's injected into the DOM (<head>) by Dealer.com's API, we
   * wait an additional and arbitrary 1 second to ensure the code inside
   * "dealerdotcom.js" has executed before firing the custom event
   * "ws-inv-listing-rendered" on the window object.  This informs the
   * Dealer.com API to start injecting the Vehicle CTAs onto the page.
   */
  if (paramsInitial?.pageType === PAGE_TYPES.SRP) {
    const timer1 = setInterval(() => {
      if (document.querySelector('script[data-web-api-id="a2zsync"]')) {
        clearInterval(timer1)
        /**
         * Since there is no way to determine whether the logic inside "dealer.com.js"
         * has been executed, we arbitrarily try to dispatch the custom event below
         * once per second, up to six times.
         */
        const timer2 = setInterval(() => {
          window.dispatchEvent(new Event('ws-inv-listing-rendered'))
        }, 1000)
        setTimeout(() => {
          clearInterval(timer2)
        }, 5000)
      }
    }, 250)
  }

  addScript(
    `${sourceDomain}/static/dist/v9/media/js/ddc/v1/dist/ddc.jquery.async.each.min.js?r=${ts}`
  )
  addScript(`${sourceDomain}/static/dist/v9/media/js/ddc/v1/dist/ddc.min.js?r=${ts}`)
  addScript(
    `${sourceDomain}/static/assets/libs/ddc-core-js-polyfills/1.0.8/ddc-core-js-polyfills.min.js?r=${ts}`
  )
  addScript(`${sourceDomain}/static/assets/libs/ddc-js-api/${ddcJsApiVersion}/loader.js?r=${ts}`)
  addScript(`${sourceDomain}/static/assets/libs/ddc-js-api/${ddcJsApiVersion}/lib.js?r=${ts}`)
}

const DealerDotComMock = (props) => {
  const {paramsInitial} = props
  const ranOnce = useRef(false)

  useEffect(() => {
    if (!ranOnce.current) {
      ranOnce.current = true
      attachMutationObserver()
      injectCode(props)
    }
  }, [ranOnce.current])

  if (paramsInitial?.pageType === PAGE_TYPES.SRP) {
    return <div className="ws-inv-listing" />
  }

  return null
}

DealerDotComMock.propTypes = {
  paramsInitial: PropTypes.shape({
    clientUuid: PropTypes.string,
    dealershipId: PropTypes.string,
    sourceDomain: PropTypes.string,
    pageType: PropTypes.string,
  }),
}

export default DealerDotComMock
