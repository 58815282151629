import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import {useVehiclesContext} from '../../hooks/useVehicles'
import {DEALER_INTEGRATION_SCRIPTS, PAGE_TYPES, VEHICLE_CONDITIONS_PROP_TYPE} from '../../constants'

const getGenerateButtonVerbiage = ({buttonActive} = {}) => (
  <>
    the <span className="tw-whitespace-nowrap">“Generate Test Page”</span> button to{' '}
    {buttonActive || 'enable'} CTAs.
  </>
)

const getAndClickGenerateButtonVerbiage = ({buttonActive} = {}) => (
  <>and click {getGenerateButtonVerbiage({buttonActive})}</>
)

const getVariableSetToOptionVerbiage = ({
  variableLabel,
  variableName,
  optionLabel,
  optionClassName,
}) => (
  <>
    <span className="tw-font-semibold tw-whitespace-nowrap">{variableLabel}</span> (
    <code className="tw-text-xs">{variableName}</code>) is set to{' '}
    <span className={classNames('tw-font-semibold', optionClassName)}>{optionLabel}</span>
  </>
)

const VehicleCtaPopover = ({index, vehicle}) => {
  const {paramsInitial, hasParamsOrVehiclesChanged} = useVehiclesContext()

  const isPageTypeVdp = paramsInitial.pageType === PAGE_TYPES.VDP
  const isPageTypeSrp = paramsInitial.pageType === PAGE_TYPES.SRP
  const isPageTypeVdpAndNotFirstVehicleCard = isPageTypeVdp && index
  const isPageTypeSrpAndEnableSearchPageIsOff = isPageTypeSrp && !paramsInitial.enableSearchPage
  const isIntegrationDealerDotCom =
    paramsInitial.integration === DEALER_INTEGRATION_SCRIPTS.DEALER_DOT_COM
  const isScopeVehicleTypeSetAndDoesNotMatchVehicleType =
    paramsInitial.scopeVehicleType !== '' && paramsInitial.scopeVehicleType !== vehicle.condition

  const getPopoverText = () => {
    if (hasParamsOrVehiclesChanged) {
      return (
        <>
          You’ve modified the configuration of this test page. Click {getGenerateButtonVerbiage()}
        </>
      )
    }

    if (isPageTypeVdpAndNotFirstVehicleCard) {
      return (
        <>
          <p className="tw-mb-2">
            This page is currently configured as a <span className="tw-font-semibold">VDP</span>,
            and as such only the CTA inside the first Vehicle Card is enabled.
          </p>
          <p>
            Select <span className="tw-font-semibold">SRP</span>{' '}
            {getAndClickGenerateButtonVerbiage()}
          </p>
        </>
      )
    }

    if (!paramsInitial.integration) {
      return (
        <>
          Select an <span className="tw-font-semibold">Integration</span> option{' '}
          {getAndClickGenerateButtonVerbiage({buttonActive: 'create'})}
        </>
      )
    }

    const ctaNotAvailableBecause = `CTA ${
      isIntegrationDealerDotCom
        ? 'will not be loaded by the Dealer.com API'
        : 'was removed by the integration script'
    } because`

    if (!paramsInitial.enableA2zDr) {
      return (
        <>
          <p className="tw-mb-2">
            {ctaNotAvailableBecause}{' '}
            {getVariableSetToOptionVerbiage({
              variableLabel: 'DigitalLane',
              variableName: 'enableA2zDr',
              optionLabel: 'Off',
            })}
            .
          </p>
          <p>
            Set <span className="tw-font-semibold">DigitalLane</span> to{' '}
            <span className="tw-font-semibold tw-text-green-700">On</span>{' '}
            {getAndClickGenerateButtonVerbiage({buttonActive: 'create'})}
          </p>
        </>
      )
    }

    if (isPageTypeSrpAndEnableSearchPageIsOff) {
      return (
        <>
          <p className="tw-mb-2">
            {ctaNotAvailableBecause}{' '}
            {getVariableSetToOptionVerbiage({
              variableLabel: 'Search Page',
              variableName: 'enableSearchPage',
              optionLabel: 'Off',
              optionClassName: 'tw-text-red-700',
            })}
            .
          </p>
          <p>
            Set <span className="tw-font-semibold tw-whitespace-nowrap">Search Page</span> to{' '}
            <span className="tw-font-semibold tw-text-green-700">On</span>{' '}
            {getAndClickGenerateButtonVerbiage({buttonActive: 'create'})}
          </p>
        </>
      )
    }

    if (isIntegrationDealerDotCom && isScopeVehicleTypeSetAndDoesNotMatchVehicleType) {
      return (
        <p>
          {ctaNotAvailableBecause}{' '}
          {getVariableSetToOptionVerbiage({
            variableLabel: 'Vehicle Type',
            variableName: 'scopeVehicleType',
            optionLabel:
              paramsInitial.scopeVehicleType.charAt(0).toUpperCase() +
              paramsInitial.scopeVehicleType.slice(1),
          })}
          .
        </p>
      )
    }

    return <>Unknown Error</>
  }

  return getPopoverText()
}

VehicleCtaPopover.propTypes = {
  index: PropTypes.number,
  vehicle: PropTypes.shape({
    condition: VEHICLE_CONDITIONS_PROP_TYPE,
  }),
}

export default VehicleCtaPopover
