import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './ButtonGroup.module.scss'

export const BUTTON_ACTIVE_CLASS = 'tw-bg-white hover:tw-bg-gray-100 tw-font-medium'
export const BUTTON_INACTIVE_CLASS = classNames(
  'tw-bg-gray-200 hover:tw-bg-gray-100 tw-text-gray-500 hover:tw-gray-700',
  styles.buttonInactive
)

const ButtonGroup = ({name, handleParamsUpdate, buttons}) => {
  return (
    <div className="tw-max-w-lg">
      <span className="tw-relative tw-z-0 tw-inline-flex tw-rounded-md">
        {buttons.map((button, index) => {
          const isButtonFirst = !index
          const isButtonLast = index === buttons.length - 1
          const buttonClassName = classNames(
            'tw-relative tw-inline-flex tw-items-center tw-px-4 tw-py-1.5 tw-border tw-border-gray-300',
            {
              'tw-rounded-l-md': isButtonFirst,
              'tw--ml-px': !isButtonFirst,
              'tw-rounded-r-md': isButtonLast,
              [BUTTON_ACTIVE_CLASS]: button.isActive,
              [BUTTON_INACTIVE_CLASS]: !button.isActive,
              'tw-text-green-700': button.isActive && button.value === true,
              'tw-text-red-700': button.isActive && button.value === false,
            }
          )

          return (
            <button
              data-testid="buttongroup-button"
              key={`button-${index.toString()}`}
              type="button"
              className={buttonClassName}
              onClick={() => {
                handleParamsUpdate({
                  name,
                  value: button.value,
                })
              }}
            >
              {button.label}
            </button>
          )
        })}
      </span>
    </div>
  )
}

ButtonGroup.propTypes = {
  name: PropTypes.string,
  handleParamsUpdate: PropTypes.func,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.number]),
      isActive: PropTypes.bool,
      handleClick: PropTypes.func,
    })
  ),
}

export default ButtonGroup
