import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faChevronDown} from '@fortawesome/free-solid-svg-icons'
import {Transition} from '@headlessui/react'
import {TRANSITION_CLASS} from 'Src/components/CreditApp/Utilities/Accordion'
import useLocalStorage, {getKey} from '../../hooks/useLocalStorage'

export const style = {
  boxShadow: '0 4px 8px rgba(0,0,0,.01), 0 0 8px rgba(0,0,0,.01), 0 0 2px rgba(0,0,0,.05)',
}

export const LOCAL_STORAGE_PREFIX = 'test-page.box.'
export const TRANSITION_CLASS_COLLAPSING = 'az-collapsing'

const Box = ({children, heading}) => {
  const localStorageKey = `${LOCAL_STORAGE_PREFIX}${getKey(heading)}`
  const [isOpenLocalStorage, setIsOpenLocalStorage] = useLocalStorage(localStorageKey, true)
  const [isOpen, setIsOpen] = useState(isOpenLocalStorage)

  const handleToggleButtonClick = () => {
    setIsOpen((prevState) => !prevState)
  }

  useEffect(() => {
    setIsOpenLocalStorage(isOpen)
  }, [isOpen])

  return (
    <div className="tw-rounded-xl tw-px-4 tw-bg-white" style={style}>
      <div className="tw-flex tw-items-center">
        <div className="tw-font-semibold tw-flex-1 tw-py-3" data-testid="box-heading">
          {heading}
        </div>
        <button
          type="button"
          className={classNames(
            'tw-opacity-50 hover:tw-opacity-100 tw-transition tw-duration-350 tw-transform',
            {'tw-rotate-90': !isOpen}
          )}
          onClick={handleToggleButtonClick}
          aria-expanded={isOpen}
        >
          <div
            className="tw-rounded-full tw-bg-gray-200 tw-w-8 tw-h-8 tw-grid tw-place-items-center"
            data-testid="box-button"
          >
            <FontAwesomeIcon icon={faChevronDown} />
          </div>
        </button>
      </div>
      <Transition
        show={isOpen}
        unmount={false}
        className={TRANSITION_CLASS.EASE}
        enter={TRANSITION_CLASS_COLLAPSING}
        enterFrom={TRANSITION_CLASS.CLOSE}
        enterTo={TRANSITION_CLASS.OPEN}
        entered={TRANSITION_CLASS.OPENED}
        leave={TRANSITION_CLASS_COLLAPSING}
        leaveFrom={TRANSITION_CLASS.OPEN}
        leaveTo={TRANSITION_CLASS.CLOSE}
        data-testid="box-collapse"
      >
        <div
          className="tw-border-t-2 tw-pb-3 tw-border-gray-100 tw-divide-y tw-divide-gray-100"
          data-testid="box-children"
        >
          {children}
        </div>
      </Transition>
    </div>
  )
}

Box.propTypes = {
  children: PropTypes.node.isRequired,
  heading: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
}

export default Box
