import React from 'react'
import VehiclesGrid from './VehiclesGrid'
import AddVehicleButton from './AddVehicleButton'
import CtaCountButtons from './CtaCountButtons'
import {style} from '../BoxCollapsible/Box'

const VehiclesSection = () => (
  <section
    className="tw-mb-6 tw-rounded-xl tw-bg-white tw-px-4"
    style={{...style, borderTopWidth: 0}}
  >
    <div className="tw-flex tw-items-center">
      <div className="tw-my-3 tw-mr-4 tw-font-semibold">Vehicles</div>
      <div>
        <AddVehicleButton />
      </div>
      <CtaCountButtons />
    </div>
    <div className="tw-divide-y tw-divide-gray-100 tw-border-t-2 tw-border-gray-100 tw-py-4">
      <VehiclesGrid {...{AddVehicleButton}} />
    </div>
  </section>
)

export default VehiclesSection
