import React from 'react'
import Box from '../BoxCollapsible/Box'
import Row from '../BoxCollapsible/Row'
import ButtonGroup from '../BoxCollapsible/ButtonGroup'
import TextInput from '../BoxCollapsible/TextInput'
import TrailingSlashWarning from '../BoxCollapsible/TrailingSlashWarning'
import {useParamsContext} from '../../hooks/useParams'
import PriceLockedQuestion from './PriceLockedQuestion'
import VDPAutoOpenQuestion from './VDPAutoOpenQuestion'
import {useRowHighlighter} from '../../hooks/useRowHighlighter'
import {DEALER_INTEGRATION_SCRIPTS, VEHICLE_CONDITIONS} from '../../constants'
import SourceDomainQuestion from './SourceDomainQuestion'

const ConfigBox = () => {
  const {params, configJson, handleParamsUpdate} = useParamsContext()
  const highlightScopeVehicleType = useRowHighlighter({name: 'scopeVehicleType'})
  const highlightSourceDomain = useRowHighlighter({name: 'sourceDomain'})

  return (
    <Box heading="Configuration">
      {configJson.exists ? (
        <>
          <Row
            label="Price Locked"
            labelAlternate="isPriceLocked"
            highlight={useRowHighlighter({name: 'isPriceLocked'})}
          >
            <div className="tw-flex tw-items-center tw-gap-4">
              <ButtonGroup
                name="isPriceLocked"
                handleParamsUpdate={handleParamsUpdate}
                buttons={[
                  {
                    label: 'Yes',
                    value: true,
                    isActive: params.isPriceLocked,
                  },
                  {
                    label: 'No',
                    value: false,
                    isActive: !params.isPriceLocked,
                  },
                ]}
              />
              <PriceLockedQuestion />
            </div>
          </Row>
          <Row
            label="Use Cache"
            labelAlternate="useCache"
            highlight={useRowHighlighter({name: 'useCache'})}
          >
            <ButtonGroup
              name="useCache"
              handleParamsUpdate={handleParamsUpdate}
              buttons={[
                {
                  label: 'Yes',
                  value: true,
                  isActive: params.useCache,
                },
                {
                  label: 'No',
                  value: false,
                  isActive: !params.useCache,
                },
              ]}
            />
          </Row>
        </>
      ) : null}
      <Row
        label="DigitalLane"
        labelAlternate="enableA2zDr"
        highlight={useRowHighlighter({name: 'enableA2zDr'})}
      >
        <div className="tw-flex tw-items-center tw-gap-4">
          <ButtonGroup
            name="enableA2zDr"
            handleParamsUpdate={handleParamsUpdate}
            buttons={[
              {
                label: 'On',
                value: true,
                isActive: [undefined, true].includes(params.enableA2zDr),
              },
              {
                label: 'Off',
                value: false,
                isActive: params.enableA2zDr === false,
              },
            ]}
          />
        </div>
      </Row>
      <Row
        label="Search Page"
        labelAlternate="enableSearchPage"
        highlight={useRowHighlighter({name: 'enableSearchPage'})}
      >
        <div className="tw-flex tw-items-center tw-gap-4">
          <ButtonGroup
            name="enableSearchPage"
            handleParamsUpdate={handleParamsUpdate}
            buttons={[
              {
                label: 'On',
                value: true,
                isActive: [undefined, true].includes(params.enableSearchPage),
              },
              {
                label: 'Off',
                value: false,
                isActive: params.enableSearchPage === false,
              },
            ]}
          />
        </div>
      </Row>
      {params.integration === DEALER_INTEGRATION_SCRIPTS.DEALER_DOT_COM ? (
        <Row
          label="Vehicle Type"
          labelAlternate="scopeVehicleType"
          highlight={highlightScopeVehicleType}
        >
          <div className="tw-flex tw-items-center tw-gap-4">
            <ButtonGroup
              name="scopeVehicleType"
              handleParamsUpdate={handleParamsUpdate}
              buttons={[
                {
                  label: 'Both',
                  value: '',
                  isActive: !params.scopeVehicleType,
                },
                {
                  label: 'New',
                  value: VEHICLE_CONDITIONS.NEW,
                  isActive: params.scopeVehicleType === VEHICLE_CONDITIONS.NEW,
                },
                {
                  label: 'Used',
                  value: VEHICLE_CONDITIONS.USED,
                  isActive: params.scopeVehicleType === VEHICLE_CONDITIONS.USED,
                },
              ]}
            />
          </div>
        </Row>
      ) : null}
      <Row label="API URL" labelAlternate="apiUrl" highlight={useRowHighlighter({name: 'apiUrl'})}>
        <TextInput name="apiUrl" value={params.apiUrl} handleUpdate={handleParamsUpdate} />
        {params.apiUrl?.substr(-1) === '/' ? <TrailingSlashWarning /> : null}
      </Row>
      <Row
        label="API Token"
        labelAlternate="apiToken"
        highlight={useRowHighlighter({name: 'apiToken'})}
      >
        <TextInput name="apiToken" value={params.apiToken} handleUpdate={handleParamsUpdate} />
      </Row>
      {params.useLegacySnippet ? (
        <Row label="Source Domain" labelAlternate="sourceDomain" highlight={highlightSourceDomain}>
          <div className="tw-mr-2 tw-flex tw-items-center tw-gap-2">
            <div className="tw-flex-1">
              <TextInput
                name="sourceDomain"
                value={params.sourceDomain}
                handleUpdate={handleParamsUpdate}
              />
            </div>
            <SourceDomainQuestion />
            {params.sourceDomain?.substr(-1) === '/' ? <TrailingSlashWarning /> : null}
          </div>
        </Row>
      ) : null}
      <Row label="Theme" labelAlternate="theme" highlight={useRowHighlighter({name: 'theme'})}>
        <TextInput
          name="theme"
          value={params.theme}
          handleUpdate={handleParamsUpdate}
          placeholder="default"
        />
      </Row>
      {configJson.exists ? (
        <Row
          label={
            <div>
              <div className="tw-absolute tw-bottom-full tw-right-0 tw-text-[0.6em] tw-uppercase tw-no-underline tw-opacity-50">
                Registration Form
              </div>
              <div>Phone Required</div>
            </div>
          }
          labelAlternate="registrationForm.phoneRequired"
          highlight={useRowHighlighter({name: 'registrationForm.phoneRequired'})}
        >
          <div className="tw-flex tw-items-center tw-gap-4">
            <ButtonGroup
              name="registrationForm.phoneRequired"
              handleParamsUpdate={handleParamsUpdate}
              buttons={[
                {
                  label: 'Yes',
                  value: true,
                  isActive: params.registrationForm?.phoneRequired,
                },
                {
                  label: 'No',
                  value: false,
                  isActive: !params.registrationForm?.phoneRequired,
                },
              ]}
            />
          </div>
        </Row>
      ) : null}
      {configJson.exists ? (
        <Row
          label="VDP Auto Open"
          labelAlternate="vdpAutoOpen"
          highlight={useRowHighlighter({name: 'vdpAutoOpen'})}
        >
          <div className="tw-flex tw-items-center tw-gap-4">
            <ButtonGroup
              name="vdpAutoOpen"
              handleParamsUpdate={handleParamsUpdate}
              buttons={[
                {
                  label: 'Yes',
                  value: true,
                  isActive: params.vdpAutoOpen,
                },
                {
                  label: 'No',
                  value: false,
                  isActive: !params.vdpAutoOpen,
                },
              ]}
            />
            <VDPAutoOpenQuestion />
          </div>
        </Row>
      ) : null}
    </Box>
  )
}

export default ConfigBox
