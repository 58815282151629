import React from 'react'
import PropTypes from 'prop-types'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faQuestion} from '@fortawesome/free-solid-svg-icons/faQuestion'
import PopoverHover from 'Src/components/Popovers/PopoverHover'

const IconPopoverWrapper = ({
  children,
  panelClassName = undefined,
  faIcon,
  popoverOffset,
  popoverPlacement = 'right',
}) => {
  return (
    <PopoverHover
      {...{
        content: children,
        panelClassName,
        options: {
          placement: popoverPlacement,
          modifiers: [{name: 'offset', options: {offset: popoverOffset}}],
        },
      }}
    >
      <div className="tw-duration-350 tw-grid tw-h-6 tw-w-6 tw-cursor-help tw-place-items-center tw-rounded-full tw-bg-gray-200 tw-opacity-50 tw-transition hover:tw-opacity-100">
        <FontAwesomeIcon icon={faIcon ?? faQuestion} className="tw-h-3 tw-w-3" />
      </div>
    </PopoverHover>
  )
}

export default IconPopoverWrapper

IconPopoverWrapper.propTypes = {
  children: PropTypes.node,
  panelClassName: PropTypes.string,
  faIcon: PropTypes.shape({}),
  popoverOffset: PropTypes.arrayOf(PropTypes.number),
  popoverPlacement: PropTypes.string,
}
