import React from 'react'
import PropTypes from 'prop-types'
import ConfigNotFound from './ConfigNotFound'

const ConfigValidation = ({configJson, paramsInitial}) => {
  const errors = []

  if (!configJson.exists) {
    // "clientUuid" query param not provided.
    if (!paramsInitial?.clientUuid) {
      errors.push(
        <>
          Enter a <span className="tw-font-semibold">Client UUID</span>.
        </>
      )
    }

    // "dealershipId" query param not provided.
    if (!paramsInitial?.dealershipId) {
      errors.push(
        <>
          Enter a <span className="tw-font-semibold">Dealership ID</span>.
        </>
      )
    }

    if (paramsInitial.useLegacySnippet && !paramsInitial?.integration) {
      errors.push(
        <>
          Select an <span className="tw-font-semibold">Integration</span> option. &nbsp;
          <span className="tw-text-gray-500">
            This is required when{' '}
            <span className="tw-font-semibold tw-text-gray-700">Legacy Snipppet</span> is{' '}
            <span className="tw-font-semibold tw-text-green-700">Yes</span>.
          </span>
        </>
      )
    }

    // Required query params provided, but "config.json" file does not exist.
    if (!errors.length) {
      return <ConfigNotFound {...{paramsInitial, configJson}} />
    }
  }

  if (!errors.length) {
    return null
  }

  return (
    <div className="tw-mt-4">
      <p className="tw-mb-2">
        Please fix the following issue{errors.length > 1 ? 's' : ''} in order to generate a valid
        Test Page:
      </p>
      <ul className="tw-list-disc tw-ml-5 tw-mb-4">
        {errors.map((error, index) => (
          <li key={`error-${index.toString()}`} className="tw-mb-2">
            {error}
          </li>
        ))}
      </ul>
      {configJson.exists ? (
        <>Once you’ve updated the config.json file, refresh this page.</>
      ) : (
        <>
          Once you’ve entered this information, click the{' '}
          <span className="tw-whitespace-nowrap">“Generate Test Page”</span> button below.
        </>
      )}
    </div>
  )
}

ConfigValidation.propTypes = {
  configJson: PropTypes.shape({
    url: PropTypes.string,
    exists: PropTypes.bool,
  }),
  paramsInitial: PropTypes.shape({
    clientUuid: PropTypes.string,
    dealershipId: PropTypes.string,
    useLegacySnippet: PropTypes.bool,
    integration: PropTypes.string,
  }),
}

export default ConfigValidation
