import React, {useState, useRef, useEffect} from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faTimes} from '@fortawesome/free-solid-svg-icons'
import {useVehiclesContext} from '../../hooks/useVehicles'
import VehicleCta from './VehicleCta'
import {VEHICLE_CONDITIONS, VEHICLE_CONDITIONS_PROP_TYPE} from '../../constants'
import {BUTTON_ACTIVE_CLASS, BUTTON_INACTIVE_CLASS} from '../BoxCollapsible/ButtonGroup'

const VehicleCard = ({index, vehicle, focusOnVin}) => {
  const {uuid, vin, condition} = vehicle
  const {handleUpdateVehicle, handleDeleteVehicle} = useVehiclesContext()
  const [vinValue, setVinValue] = useState(vin)
  const vinInputRef = useRef()

  useEffect(() => {
    if (focusOnVin) {
      vinInputRef.current.focus()
    }
  }, [])

  const vinInputId = `vin-${uuid}`

  const handleConditionButtonClick = (event) => {
    handleUpdateVehicle({...vehicle, condition: event.target.value})
  }

  const handleVinBlur = (e) => {
    handleUpdateVehicle({...vehicle, vin: e.target.value.toUpperCase()})
  }

  const handleVinChange = (e) => setVinValue(e.target.value.toUpperCase())

  return (
    <div className="tw-w-64 tw-border-2 tw-border-gray-300 tw-rounded-lg tw-bg-white tw-overflow-hidden">
      <div className="tw-flex tw-font-medium tw-border-b tw-border-gray-300 tw-px-3 tw-py-2 tw-bg-gray-50">
        <div className="tw-flex-1">Vehicle {index + 1}</div>
        <button
          type="button"
          className="tw-content-end tw-w-6 tw-h-6 tw-bg-gray-200 hover:tw-bg-gray-300 tw-rounded tw-text-xs"
          onClick={() => handleDeleteVehicle({uuid})}
        >
          <FontAwesomeIcon icon={faTimes} />
        </button>
      </div>
      <div className="tw-p-3">
        {/* VIN */}
        <div className="tw-mb-2">
          <label htmlFor={vinInputId} className="tw-block text-gray-700 tw-text-gray-600 tw-mb-2">
            VIN
          </label>
          <div>
            <input
              ref={vinInputRef}
              id={vinInputId}
              type="text"
              value={vinValue}
              maxLength={20}
              className="focus:tw-ring-indigo-500 focus:tw-border-indigo-500 tw-block tw-w-full tw-border-gray-300 tw-rounded-md"
              onBlur={handleVinBlur}
              onChange={handleVinChange}
            />
            <span id={`vin-${uuid}-validation-message`} className="tw-text-red-600" />
          </div>
        </div>
        {/* Condition */}
        <div className="tw-mb-2">
          <label className="tw-block text-gray-700 tw-text-gray-600 tw-mb-2">Condition</label>
          <div>
            <span className="tw-relative tw-z-0 tw-flex tw-rounded-md">
              <button
                type="button"
                className={classNames(
                  'tw-flex-1 tw-relative tw-px-4 tw-py-2 tw-rounded-l-md tw-border tw-border-gray-300',
                  {
                    [BUTTON_ACTIVE_CLASS]: condition === VEHICLE_CONDITIONS.NEW,
                    [BUTTON_INACTIVE_CLASS]: condition !== VEHICLE_CONDITIONS.NEW,
                  }
                )}
                value={VEHICLE_CONDITIONS.NEW}
                onClick={handleConditionButtonClick}
              >
                New
              </button>
              <button
                type="button"
                className={classNames(
                  'tw-flex-1 tw--ml-px tw-relative tw-px-4 tw-py-2 tw-rounded-r-md tw-border tw-border-gray-300 hover:tw-bg-gray-100',
                  {
                    [BUTTON_ACTIVE_CLASS]: condition === VEHICLE_CONDITIONS.USED,
                    [BUTTON_INACTIVE_CLASS]: condition !== VEHICLE_CONDITIONS.USED,
                  }
                )}
                value={VEHICLE_CONDITIONS.USED}
                onClick={handleConditionButtonClick}
              >
                Used
              </button>
            </span>
          </div>
        </div>
        {/* CTA */}
        <div className="tw-mt-3">
          <VehicleCta
            {...{
              index,
              vehicle,
            }}
          />
        </div>
      </div>
    </div>
  )
}

VehicleCard.propTypes = {
  index: PropTypes.number,
  vehicle: PropTypes.shape({
    uuid: PropTypes.string,
    vin: PropTypes.string,
    condition: VEHICLE_CONDITIONS_PROP_TYPE,
  }),
  focusOnVin: PropTypes.bool,
}

export default VehicleCard
