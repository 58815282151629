import React, {useState, useRef, useMemo, useCallback} from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import PopoverHover from 'Src/components/Popovers/PopoverHover'
import {useVehiclesContext} from 'Src/test-page/hooks/useVehicles'
import useMutationObserver from 'Src/test-page/hooks/useMutationObserver'
import DealerInspireVdpCta from 'Src/test-page/IntegrationMocks/dealerinspire/DealerInspireVdpCta'
import DealerInspireSrpCta from 'Src/test-page/IntegrationMocks/dealerinspire/DealerInspireSrpCta'
import DealerDotComVdpCta from 'Src/test-page/IntegrationMocks/dealerdotcom/DealerDotComVdpCta'
import DealerDotComSrpCta from 'Src/test-page/IntegrationMocks/dealerdotcom/DealerDotComSrpCta'
import DealerEProcessCta from 'Src/test-page/IntegrationMocks/dealereprocess/DealerEProcessCta'
import DealerOnCta from 'Src/test-page/IntegrationMocks/dealeron/DealerOnCta'
import DealerCarSearchCta from 'Src/test-page/IntegrationMocks/dealer-car-search/DealerCarSearchCta'
import NabthatCta from 'Src/test-page/IntegrationMocks/nabthat/NabthatCta'
import TeamVelocityCta from 'Src/test-page/IntegrationMocks/team-velocity/TeamVelocityCta'
import MotiveCta from 'Src/test-page/IntegrationMocks/motive/MotiveCta'
import DummyCta from 'Src/test-page/IntegrationMocks/DummyCta'
import DummyCtaWithNotAvailable from 'Src/test-page/IntegrationMocks/DummyCtaWithNotAvailable'
import VehicleCtaPopover from './VehicleCtaPopover'
import {
  DEALER_INTEGRATION_SCRIPTS,
  PAGE_TYPES,
  VEHICLE_CONDITIONS,
  VEHICLE_CONDITIONS_PROP_TYPE,
} from '../../constants'

const MUTATION_OBSERVER_OPTIONS = {
  attributes: false,
  characterData: false,
  childList: true,
  subtree: false,
}

const Div = ({children}) => <div>{children}</div>

const VehicleCta = (props) => {
  const {index, vehicle} = props
  const vehicleInitial = useMemo(() => vehicle, [])
  const {hasParamsOrVehiclesChanged, params, paramsInitial} = useVehiclesContext()
  const containerRef = useRef()
  const [isCtaAvailable, setIsCtaAvailable] = useState(true)
  const isPageTypeVdp = paramsInitial.pageType === PAGE_TYPES.VDP
  // If Page Type is "VDP", only enable the CTA in the first Vehicle Card.
  const isPageTypeVdpAndFirstVehicleCard = isPageTypeVdp && !index
  const isPageTypeSrp = paramsInitial.pageType === PAGE_TYPES.SRP
  let isCtaEnabled =
    (isPageTypeSrp || isPageTypeVdpAndFirstVehicleCard) &&
    !hasParamsOrVehiclesChanged &&
    isCtaAvailable
  const ctaLabel =
    paramsInitial.isPriceLocked && vehicle.condition === VEHICLE_CONDITIONS.NEW
      ? paramsInitial.ctaLabelForPriceLocked
      : paramsInitial.ctaLabel
  let children = isCtaAvailable ? null : <DummyCtaWithNotAvailable />
  const className = classNames(
    'tw-block tw-w-full tw-rounded-md tw-bg-blue-500 tw-px-2 tw-py-1 tw-text-center tw-text-white',
    {
      'hover:tw-bg-blue-700 hover:tw-text-white': isCtaEnabled,
      'tw-pointer-events-none tw-opacity-50': !isCtaEnabled,
    }
  )
  let Component = Div

  const mutationObserverCallback = useCallback(
    (mutationList) => {
      // Exclude Dealer.com from this MutationObserver.
      if ([DEALER_INTEGRATION_SCRIPTS.DEALER_DOT_COM].includes(paramsInitial.integration)) {
        return
      }

      mutationList.forEach((mutationItem) => {
        mutationItem.addedNodes.forEach((node) => {
          // CTA available.
          if (node.classList?.contains(paramsInitial.ctaClassifier)) {
            setIsCtaAvailable(true)
          }
        })

        mutationItem.removedNodes.forEach((node) => {
          const isCtaRemoved = node.classList?.contains(paramsInitial.ctaClassifier)
          const hasChildren = !!containerRef.current?.childElementCount

          // CTA not available.
          if (isCtaRemoved && !hasChildren) {
            setIsCtaAvailable(false)
          }
        })
      })
    },
    [containerRef.current]
  )

  // MutationObserver to detect when CTAs have been added or removed.
  useMutationObserver(containerRef, mutationObserverCallback, MUTATION_OBSERVER_OPTIONS)

  switch (paramsInitial.integration) {
    case DEALER_INTEGRATION_SCRIPTS.DEALER_INSPIRE:
      switch (paramsInitial.pageType) {
        case PAGE_TYPES.VDP:
          Component = DealerInspireVdpCta
          break
        case PAGE_TYPES.SRP:
          Component = DealerInspireSrpCta
          break
      }

      break
    case DEALER_INTEGRATION_SCRIPTS.DEALER_DOT_COM:
      {
        const isPageTypeSrpAndEnableSearchPageIsOff =
          paramsInitial.pageType === PAGE_TYPES.SRP && !paramsInitial.enableSearchPage
        const isScopeVehicleTypeSetAndDoesNotMatchVehicleType =
          paramsInitial.scopeVehicleType !== '' &&
          paramsInitial.scopeVehicleType !== vehicleInitial.condition

        if (
          !paramsInitial.enableA2zDr ||
          isPageTypeSrpAndEnableSearchPageIsOff ||
          isScopeVehicleTypeSetAndDoesNotMatchVehicleType
        ) {
          children = <DummyCtaWithNotAvailable ctaLabel="CTA Will Not Load" />
          isCtaEnabled = false
        }
      }

      switch (paramsInitial.pageType) {
        case PAGE_TYPES.VDP:
          Component = DealerDotComVdpCta
          break
        case PAGE_TYPES.SRP:
          Component = DealerDotComSrpCta
          break
      }

      break
    case DEALER_INTEGRATION_SCRIPTS.DEALER_EPROCESS:
      Component = DealerEProcessCta
      break
    case DEALER_INTEGRATION_SCRIPTS.DEALER_ON:
      Component = DealerOnCta
      break
    case DEALER_INTEGRATION_SCRIPTS.DEALER_CAR_SEARCH:
      Component = DealerCarSearchCta
      break
    case DEALER_INTEGRATION_SCRIPTS.NABTHAT:
      Component = NabthatCta
      break
    case DEALER_INTEGRATION_SCRIPTS.TEAM_VELOCITY:
      Component = TeamVelocityCta
      break
    case DEALER_INTEGRATION_SCRIPTS.MOTIVE:
      Component = MotiveCta
      break
    default:
      children =
        params.integration === paramsInitial.integration ? (
          <DummyCtaWithNotAvailable ctaLabel="No Integration Selected" />
        ) : (
          <DummyCta {...{ctaLabel, className}} />
        )
      isCtaEnabled = false
  }

  return (
    <PopoverHover
      content={isCtaEnabled ? null : VehicleCtaPopover(props)}
      className="tw-block"
      buttonElement="div"
      buttonClassName={classNames('tw-outline-none', {'!tw-cursor-not-allowed': !isCtaEnabled})}
      panelClassName="tw-w-60 tw-text-sm"
      options={{placement: 'bottom', modifiers: [{name: 'offset', options: {offset: [0, 8]}}]}}
    >
      <Component {...{...props, ctaLabel, className, isDisabled: !isCtaEnabled, containerRef}}>
        {children}
      </Component>
    </PopoverHover>
  )
}

export default VehicleCta

VehicleCta.propTypes = {
  index: PropTypes.number,
  vehicle: PropTypes.shape({
    condition: VEHICLE_CONDITIONS_PROP_TYPE,
    uuid: PropTypes.string,
  }),
}

Div.propTypes = {
  children: PropTypes.node,
}
