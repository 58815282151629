import {useParamsContext} from './useParams'
import {PARAM_HIGHLIGHTS} from '../constants'

export const useRowHighlighter = ({name = ''} = {}) => {
  const {isParamInConfig, isParamOverridden} = useParamsContext()

  if (!name) {
    return ''
  }

  return !isParamInConfig({name})
    ? PARAM_HIGHLIGHTS.UNDEFINED
    : isParamOverridden({name})
    ? PARAM_HIGHLIGHTS.OVERRIDDEN
    : ''
}
