import React from 'react'
import PropTypes from 'prop-types'
import IconPopoverWrapper from '../IconPopoverWrapper'

const List = ({items, className, title}) => {
  return (
    <dl className={className}>
      <dt>{title}:</dt>
      {items.map((example) => (
        <dd
          className="tw-pl-2"
          key={example.trim()}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{__html: example}}
        />
      ))}
    </dl>
  )
}

const SourceDomainQuestion = () => {
  return (
    <IconPopoverWrapper {...{panelClassName: '', popoverOffset: [50, 14]}}>
      <div className="tw-font-medium tw-mb-1">Source Domain</div>
      <div className="tw-text-xs tw-text-gray-600 tw-leading-4">
        <p className="tw-mb-1">URL for iframe source code.</p>
        <List
          title="Example 1"
          items={[
            'Deploy branch to QA1',
            'On QA1 set <code>sourceDomain</code> to QA1 URL',
            'Click View & Pricing',
            'QA1 iframe loads from QA1',
          ]}
        />
        <List
          className="tw-pt-2"
          title="Example 2"
          items={[
            'Deploy branch to QA2',
            'On QA1 set <code>sourceDomain</code> to QA2 URL',
            'Click View & Pricing',
            'QA1 iframe loads from QA2',
          ]}
        />
      </div>
    </IconPopoverWrapper>
  )
}

export default SourceDomainQuestion

List.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string),
  className: PropTypes.string,
  title: PropTypes.string,
}
