import React from 'react'
import PropTypes from 'prop-types'
import DummyCta from './DummyCta'

const DummyCtaWithNotAvailable = ({ctaLabel = 'CTA Removed'}) => (
  <DummyCta
    className="tw-border-red-700 tw-rounded-md tw-px-2 tw-py-0.5 tw-border-dashed tw-w-full tw-border-2 tw-select-none tw-pointer-events-none tw-cursor-not-allowed"
    ctaLabel={ctaLabel}
    disabled
  />
)

export default DummyCtaWithNotAvailable

DummyCtaWithNotAvailable.propTypes = {
  ctaLabel: PropTypes.string,
}
