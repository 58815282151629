import {paramsToConfig} from '../helpers'
import {useParamsContext} from '../hooks/useParams'

const FetchInterceptor = () => {
  const {paramsInitial, configJson} = useParamsContext()
  const originalFetch = window.fetch
  const configJsonUrlObject = new URL(configJson.url)

  window.fetch = (url, options) => {
    const urlObject = new URL(url)

    if (
      `${urlObject.pathname}${urlObject.search}` ===
      `${configJsonUrlObject.pathname}${configJsonUrlObject.search}`
    ) {
      return Promise.resolve({
        ok: true,
        json: () => paramsToConfig({params: paramsInitial}),
      })
    }

    if (urlObject.pathname.match(/\/config\.json$/)) {
      return Promise.resolve({
        ok: false,
      })
    }

    return originalFetch(url, options)
  }

  return null
}

export default FetchInterceptor
