import _ from 'lodash'

// Convert the "params" object to match the shape of the "config" object from config.json
export const paramsToConfig = ({params}) => {
  const {gtmId, measurementIds, pageType, integration, ...config} = params
  config.analytics = {gtmId, measurementIds}

  if (!params.useLegacySnippet && integration) {
    config.integration = integration
  }

  return config
}

export const getKeyValuePairsFromObjectByKey = (object, key) => {
  return Object.entries(object).reduce((acc, [itemKey, itemValue]) => {
    acc[itemKey] = itemValue[key]

    return acc
  }, {})
}

// Source: https://stackoverflow.com/a/70592867/1694854
export const flattenObjectRecursively = (object, prefix = '') => {
  const result = {}

  Object.entries(object).forEach(([key, value]) => {
    if (typeof value === 'object' && !Array.isArray(value)) {
      Object.assign(result, flattenObjectRecursively(value, `${prefix}${key}.`))

      return
    }

    result[`${prefix}${key}`] = value
  })

  return result
}

// Source: https://stackoverflow.com/a/72715038/1694854
export const unflattenObjectRecursively = (object) => {
  const result = {}

  _.keys(object).forEach((key) => {
    _.set(result, key, object[key])
  })

  return result
}

export const deleteEmptyPropertiesInObjectRecursively = (object) => {
  const result = {}

  Object.entries(object).forEach(([key, value]) => {
    const isValueNullOrUndefined = [null, undefined].includes(value)
    const isTypeObject =
      !isValueNullOrUndefined && typeof value === 'object' && !Array.isArray(value)
    const valueNew =
      isTypeObject && !isValueNullOrUndefined
        ? deleteEmptyPropertiesInObjectRecursively(value)
        : value

    if (
      (!isTypeObject && !isValueNullOrUndefined) ||
      (isTypeObject && Object.keys(valueNew).length)
    ) {
      result[key] = valueNew
    }
  })

  return result
}
