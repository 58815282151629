import React, {useMemo} from 'react'
import Box from '../BoxCollapsible/Box'
import Row from '../BoxCollapsible/Row'
import ButtonGroup from '../BoxCollapsible/ButtonGroup'
import RadioGroup from '../BoxCollapsible/RadioGroup'
import {useParamsContext} from '../../hooks/useParams'
import {DEALER_INTEGRATIONS, PAGE_TYPES} from '../../constants'
import PageTypeQuestion from './PageTypeQuestion'
import LegacySnippetQuestion from './LegacySnippetQuestion'
import {useRowHighlighter} from '../../hooks/useRowHighlighter'

const SettingsBox = () => {
  const {params, paramsInitial, configJson, handleParamsUpdate} = useParamsContext()

  const dealerIntegrations = useMemo(
    () =>
      Object.values(DEALER_INTEGRATIONS).map((dealerIntegration) => {
        return {
          label: dealerIntegration.name,
          labelAlternate: dealerIntegration.script,
          value: dealerIntegration.script,
          isActive: params?.integration === dealerIntegration.script,
        }
      }),
    [params?.integration]
  )

  return (
    <Box heading="Settings">
      {configJson.exists ? (
        <Row label="Page Type">
          <div className="tw-flex tw-items-center tw-gap-4">
            <ButtonGroup
              name="pageType"
              handleParamsUpdate={handleParamsUpdate}
              buttons={[
                {
                  label: 'VDP',
                  value: PAGE_TYPES.VDP,
                  isActive: params.pageType === PAGE_TYPES.VDP,
                },
                {
                  label: 'SRP',
                  value: PAGE_TYPES.SRP,
                  isActive: params.pageType === PAGE_TYPES.SRP,
                },
              ]}
            />
            <PageTypeQuestion />
          </div>
        </Row>
      ) : null}
      <Row
        label="Integration"
        labelAlternate={params.useLegacySnippet ? undefined : 'integration'}
        highlight={useRowHighlighter({
          name:
            paramsInitial?.useLegacySnippet === false && params.useLegacySnippet === false
              ? 'integration'
              : '',
        })}
      >
        <RadioGroup
          name="integration"
          className="tw-leading-5"
          handleParamsUpdate={handleParamsUpdate}
          options={[
            {
              label: <span className="tw-text-gray-500">None</span>,
              value: '',
              isActive: !params?.integration,
            },
            ...dealerIntegrations,
          ]}
        />
      </Row>
      <Row label="Legacy Snippet" labelAlternate="useLegacySnippet">
        <div className="tw-flex tw-items-center tw-gap-4">
          <ButtonGroup
            name="useLegacySnippet"
            handleParamsUpdate={handleParamsUpdate}
            buttons={[
              {
                label: 'Yes',
                value: true,
                isActive: params.useLegacySnippet,
              },
              {
                label: 'No',
                value: false,
                isActive: !params.useLegacySnippet,
              },
            ]}
          />
          <LegacySnippetQuestion />
        </div>
      </Row>
    </Box>
  )
}

export default SettingsBox
