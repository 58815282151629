import React, {useState} from 'react'
import PropTypes from 'prop-types'
import {useButtonsContext} from '../../hooks/useButtons'
import {flattenObjectRecursively} from '../../helpers'

const GenerateButtonHelpers = ({setIsButtonEnabled}) => {
  let seconds = 4
  const [buttonText, setButtonText] = useState('Generate Test Page')
  const {vehicles, params, paramsInitial, isParamOverridden, urlParamsObject} = useButtonsContext()
  const nonConfigParamKeys = ['clientUuid', 'dealershipId', 'integration', 'pageType']

  const updateButton = (event) => {
    event.preventDefault()
    setIsButtonEnabled(false)
    let timer

    const buttonTextCallback = () => {
      setButtonText(
        <>
          Loading {--seconds ? `in ${seconds} ` : ''}
          &hellip;
        </>
      )

      if (!seconds) {
        clearInterval(timer)
      }
    }

    buttonTextCallback()
    timer = setInterval(buttonTextCallback, 1000)
  }

  const redirect = () => {
    const newParams = new URLSearchParams(flattenObjectRecursively(params))
    const paramsToUse = Array.from(newParams.entries()).filter(([newParamKey, newParamVal]) => {
      // Always include these in the URL.
      if (nonConfigParamKeys.includes(newParamKey)) {
        return true
      }

      // Does the query param exists in the original request?
      const oldParamExists = Object.keys(urlParamsObject).includes(newParamKey)
      // Has the input value changed (from the query param override or config)?
      const paramValsMatch =
        typeof paramsInitial[newParamKey] === 'undefined'
          ? false
          : newParamVal === paramsInitial[newParamKey].toString()
      // Does the new param value match the config value?
      const matchesConfig = !isParamOverridden({name: newParamKey, value: newParamVal})

      return (oldParamExists && !matchesConfig) || (!paramValsMatch && !matchesConfig)
    })

    vehicles.forEach((vehicle) => {
      paramsToUse.push(['vehicle[]', `${vehicle.vin},${vehicle.condition}`])
    })

    setTimeout(() => {
      window.location = `?${new URLSearchParams(paramsToUse).toString()}`
    }, (seconds - 1) * 1000)
  }

  return {buttonText, updateButton, redirect}
}

GenerateButtonHelpers.propTypes = {
  setIsButtonEnabled: PropTypes.func,
}

export default GenerateButtonHelpers
