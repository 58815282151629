import {useState} from 'react'

// https://github.com/rwu823/react-addons-text-content/blob/master/src/index.js
const getText = (rootChild) => {
  let res = ''

  const rr = (child) => {
    if (typeof child === 'string' || typeof child === 'number') {
      res += child
    } else if (Array.isArray(child)) {
      child.forEach((c) => rr(c))
    } else if (child && child.props) {
      const {children} = child.props

      if (Array.isArray(children)) {
        children.forEach((c) => rr(c))
      } else {
        rr(children)
      }
    }
  }

  rr(rootChild)

  return res
}

export const getKey = (key) => {
  return getText(key).replace(/\W/g, '')
}

/**
 * useLocalStorage Custom Hook
 * Source: https://usehooks.com/useLocalStorage/
 */
const useLocalStorage = (key, initialValue) => {
  const [storedValue, setStoredValue] = useState(() => {
    if (typeof window === 'undefined') {
      return initialValue
    }

    try {
      const item = window.localStorage.getItem(key)

      return item ? JSON.parse(item) : initialValue
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)

      return initialValue
    }
  })

  const setValue = (value) => {
    try {
      const valueToStore = value instanceof Function ? value(storedValue) : value
      setStoredValue(valueToStore)

      if (typeof window !== 'undefined') {
        window.localStorage.setItem(key, JSON.stringify(valueToStore))
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
    }
  }

  return [storedValue, setValue]
}

export default useLocalStorage
