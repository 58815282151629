import React from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faExclamationTriangle} from '@fortawesome/free-solid-svg-icons'
import AddVehicleButton from './AddVehicleButton'

const VehicleNone = () => {
  return (
    <div className="tw-w-64 tw-border-2 tw-border-gray-300 tw-rounded-lg tw-bg-white tw-overflow-hidden">
      <div className="tw-flex tw-font-medium tw-border-b tw-border-gray-300 tw-px-3 tw-py-2 tw-bg-gray-50">
        <div className="tw-flex-1">No Vehicles</div>
      </div>
      <div className="tw-p-3">
        <div className="tw-mb-2">
          <FontAwesomeIcon icon={faExclamationTriangle} className="tw-text-yellow-400" /> No
          vehicles exist.
        </div>
        <div>
          <AddVehicleButton />
        </div>
      </div>
    </div>
  )
}

export default VehicleNone
