import {useState, useEffect, useRef, useContext, createContext} from 'react'
import PropTypes from 'prop-types'
import generateUuid from 'Src/utilities/generateUuid'
import {VEHICLE_CONDITIONS, DEALER_INTEGRATION_SCRIPTS} from 'Src/test-page/constants'

const vehiclesDefault = {}
export const VehiclesContext = createContext(vehiclesDefault)
export const useVehiclesContext = () => useContext(VehiclesContext)

const useVehicles = ({addErrors, removeErrors, urlParams, paramsInitial}) => {
  const [vehiclesInitial, setVehiclesInitial] = useState([])
  const [vehicles, setVehicles] = useState([])
  const [hasVehiclesChanged, setHasVehiclesChanged] = useState(false)
  const [lastAction, setLastAction] = useState('')
  const [ctaCount, setCtaCount] = useState(1)
  const ranOnce = useRef(false)

  const validateVehicles = () => {
    const allVins = vehicles.map((vehicle) => vehicle.vin).filter(Boolean)
    const duplicateVins = allVins.filter((item, index) => allVins.indexOf(item) !== index)
    const errorsToAdd = []
    const errorsToRemove = []

    allVins.forEach((vin) => {
      if (!vin.length) {
        return
      }

      const error = `VIN is duplicated (${vin}).`

      if (duplicateVins.includes(vin)) {
        errorsToAdd.push(error)
      } else {
        errorsToRemove.push(error)
      }
    })

    vehicles.forEach((vehicle, index) => {
      const validationSpan = document.getElementById(`vin-${vehicle.uuid}-validation-message`)

      if (!validationSpan) {
        return null
      }

      const error = `Vehicle ${index + 1} VIN is empty.`

      if (!vehicle.vin) {
        errorsToAdd.push(error)
        validationSpan.classList.remove('invisible')
        validationSpan.classList.add('visible')
        validationSpan.innerText = error

        return null
      }

      errorsToRemove.push(error)
      validationSpan.classList.remove('visible')
      validationSpan.classList.add('invisible')
      validationSpan.innerText = ''
    })

    if (errorsToRemove.length) {
      removeErrors(errorsToRemove)
    }

    if (errorsToAdd.length) {
      addErrors(errorsToAdd)

      return false
    }

    return true
  }

  const handleAddVehicle = ({
    uuid = generateUuid(),
    vin = '',
    condition = VEHICLE_CONDITIONS.NEW,
  } = {}) => {
    setVehicles((prevState) => {
      const newState = [...prevState]
      newState.push({
        uuid,
        vin: vin.toUpperCase(),
        condition,
      })

      return newState
    })

    setLastAction('add')
  }

  const handleUpdateVehicle = ({uuid, vin, condition}) => {
    setVehicles((prevState) =>
      prevState.map((vehicle) => (vehicle.uuid === uuid ? {...vehicle, vin, condition} : vehicle))
    )
    setLastAction('update')
  }

  const handleDeleteVehicle = ({uuid}) => {
    // Remove potentially "out of bounds" error, if it exists.
    removeErrors([`Vehicle ${vehicles.length} VIN is empty.`])

    setVehicles((prevState) => prevState.filter((vehicle) => vehicle.uuid !== uuid))
    setLastAction('delete')
  }

  const handleCtaCount = (step) => {
    setCtaCount((prevState) => prevState + (step ? 1 : -1))

    if (paramsInitial.integration === DEALER_INTEGRATION_SCRIPTS.NABTHAT) {
      setTimeout(() => {
        window.dispatchEvent(new Event('pushState'))
      })
    }
  }

  useEffect(() => {
    setHasVehiclesChanged(
      // Remove the "uuid" property before comparing objects.
      JSON.stringify(vehicles.map(({uuid, ...vehicle}) => vehicle)) !==
        JSON.stringify(vehiclesInitial.map(({uuid, ...vehicle}) => vehicle))
    )
  }, [vehicles, vehiclesInitial])

  useEffect(() => {
    if (vehicles.length) {
      if (['update', 'delete'].includes(lastAction)) {
        validateVehicles()
      }

      if (!ranOnce.current) {
        ranOnce.current = true
        validateVehicles()
      }
    }
  }, [vehicles])

  // Set vehicles for each in query params
  useEffect(() => {
    const vehicles = []

    Array.from(urlParams.getAll('vehicle[]')).forEach((param) => {
      const paramArray = param.split(',')
      const [vinParam, conditionParam] = paramArray

      let condition = conditionParam?.toLowerCase()

      if (![VEHICLE_CONDITIONS.NEW, VEHICLE_CONDITIONS.USED].includes(condition)) {
        condition = VEHICLE_CONDITIONS.NEW
      }

      const vehicle = {uuid: generateUuid(), vin: vinParam?.toUpperCase(), condition}
      handleAddVehicle(vehicle)
      vehicles.push(vehicle)
    })

    setVehiclesInitial(vehicles)
  }, [])

  return {
    vehicles,
    setVehicles,
    vehiclesInitial,
    hasVehiclesChanged,
    handleAddVehicle,
    validateVehicles,
    handleUpdateVehicle,
    handleDeleteVehicle,
    ctaCount,
    handleCtaCount,
  }
}

useVehicles.propTypes = {
  addErrors: PropTypes.func,
  removeErrors: PropTypes.func,
  urlParams: PropTypes.shape({}),
}

export default useVehicles
