import React from 'react'
import IconPopoverWrapper from '../IconPopoverWrapper'

const LegacySnippetQuestion = () => (
  <IconPopoverWrapper>
    Toggle for using the older style of the embed snippet with which we use to install DR. New style
    omits the <code>sourceDomain</code> and <code>integration</code>.
  </IconPopoverWrapper>
)

export default LegacySnippetQuestion
