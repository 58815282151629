import {useEffect} from 'react'

// Source: https://www.30secondsofcode.org/react/s/use-mutation-observer
const useMutationObserver = (
  ref,
  callback,
  options = {
    attributes: true,
    characterData: true,
    childList: true,
    subtree: true,
  }
) => {
  useEffect(() => {
    if (ref.current) {
      const observer = new MutationObserver(callback)
      observer.observe(ref.current, options)
      return () => observer.disconnect()
    }

    return () => {}
  }, [ref.current, callback, options])
}

export default useMutationObserver
