import React from 'react'
import IconPopoverWrapper from '../IconPopoverWrapper'

const PageTypeQuestion = () => (
  <IconPopoverWrapper {...{panelClassName: 'tw-w-80', popoverOffset: [40, 14]}}>
    <div className="tw-divide-y tw-divide-gray-100">
      <div className="tw-flex tw-pb-3">
        <div className="tw-flex-initial tw-text-right tw-font-semibold">
          VDP<span className="tw-inline-block tw-px-1 tw-text-gray-300">=</span>
        </div>
        <div className="tw-flex-1">
          <div className="tw-pb-1 tw-font-medium">Vehicle Details Page</div>
          <div className="tw-text-xs tw-leading-4 tw-text-gray-600">
            <p>Page showing a single vehicle with one or more CTAs for that vehicle.</p>
          </div>
        </div>
      </div>
      <div className="tw-flex tw-pt-3">
        <div className="tw-flex-initial tw-text-right tw-font-semibold">
          SRP<span className="tw-inline-block tw-px-1 tw-text-gray-300">=</span>
        </div>
        <div className="tw-flex-1">
          <div className="tw-pb-1 tw-font-medium">Search Results Page</div>
          <div className="tw-text-xs tw-leading-4 tw-text-gray-600">
            <p>Page showing multiple vehicles with one or more CTAs for each vehicle.</p>
          </div>
        </div>
      </div>
    </div>
  </IconPopoverWrapper>
)

export default PageTypeQuestion
