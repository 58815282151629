import React from 'react'
import {Helmet} from 'react-helmet'
import useParams, {ParamsContext} from '../test-page/hooks/useParams'
import useVehicles, {VehiclesContext} from '../test-page/hooks/useVehicles'
import useErrors from '../test-page/hooks/useErrors'
import {ButtonsContext} from '../test-page/hooks/useButtons'
import ConfigBox from '../test-page/components/Config/ConfigBox'
import CtaBox from '../test-page/components/CtaBox'
import DealershipBox from '../test-page/components/DealershipBox'
import AnalyticsBox from '../test-page/components/AnalyticsBox'
import SettingsBox from '../test-page/components/Settings/SettingsBox'
import LegendBox from '../test-page/components/LegendBox'
import VehiclesSection from '../test-page/components/Vehicles/VehiclesSection'
import ErrorsSection from '../test-page/components/Errors/ErrorsSection'
import ButtonsSection from '../test-page/components/Buttons/ButtonsSection'
import NotesSection from '../test-page/components/NotesSection'
import DealerIntegration from '../test-page/components/DealerIntegration'
import ConfigValidation from '../test-page/components/Config/ConfigValidation'
import logo from '../images/logo-a2z-black.svg'
import {unflattenObjectRecursively} from '../test-page/helpers'

import 'Src/styles/test.scss'
import 'Src/styles/themes/mazda/index.scss'

const TestPage = () => {
  if (typeof window === 'undefined') {
    return <noscript>No server-side rendering available for TestPage.</noscript>
  }

  /*
   * Required query params:
   * - clientUuid
   * - dealershipId
   * - vehicle[]
   * - pageType
   *
   * Optional:
   * - apiUrl
   * - apiToken
   * - dealershipName
   * - dealershipPhone
   * - dealershipEmail
   * - isPriceLocked
   * - useCache
   * - ctaClassifier
   * - gtmId (analytics)
   * - measurementIds (analytics)
   * - integration
   * - sourceDomain
   */
  const urlParams = new URLSearchParams(window.location.search)
  const urlParamsObject = unflattenObjectRecursively(Object.fromEntries(urlParams.entries()))

  const {
    params,
    paramsInitial,
    hasParamsChanged,
    handleParamsUpdate,
    isParamInConfig,
    isParamOverridden,
    configJson,
  } = useParams({
    urlParamsObject,
  })

  const {errors, addErrors, removeErrors} = useErrors()

  const {
    vehicles,
    setVehicles,
    vehiclesInitial,
    hasVehiclesChanged,
    handleAddVehicle,
    handleUpdateVehicle,
    handleDeleteVehicle,
    ctaCount,
    handleCtaCount,
  } = useVehicles({addErrors, removeErrors, urlParams, paramsInitial})

  /**
   * The "params" object is core to the functionality of this page.
   * Wait until the "params" object is populated before rendering the page.
   */
  if (!Object.keys(params).length) {
    return null
  }

  const loadDealerIntegration =
    Object.keys(paramsInitial).length && vehiclesInitial.length && configJson.exists
  const hasParamsOrVehiclesChanged = hasParamsChanged || hasVehiclesChanged
  const heading = 'DigitalLane Test Page'

  return (
    <div className="tw-mx-auto tw-max-w-screen-2xl tw-px-2 tw-py-5">
      <Helmet>
        <title>{heading} - A2Z Sync</title>
        <link
          href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,700&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <header className="tw-mb-5 tw-flex tw-h-8 tw-px-6">
        <h1 className="tw-text-2xl tw-font-medium">{heading}</h1>
        <div className="tw-ml-auto tw-flex">
          <img
            src={logo}
            alt="A2Z Sync Logo"
            className="tw-mb-1 tw-ml-auto"
            style={{minWidth: '80px', maxHeight: '30px'}}
          />
        </div>
      </header>
      <main className="tw-flex tw-flex-col tw-gap-6 tw-rounded-2xl tw-bg-gray-100 tw-p-6 md:tw-flex-row">
        {/* Left Column */}
        <div className="tw-flex tw-flex-1 tw-flex-col tw-gap-6 md:tw-w-1/2 md:tw-flex-initial lg:tw-w-2/5 xl:tw-w-1/3">
          <ParamsContext.Provider
            value={{
              params,
              paramsInitial,
              handleParamsUpdate,
              isParamInConfig,
              isParamOverridden,
              configJson,
            }}
          >
            {configJson.exists ? (
              <>
                <SettingsBox />
                <ConfigBox />
                <DealershipBox />
                <AnalyticsBox />
                <CtaBox />
                <LegendBox />
              </>
            ) : (
              <>
                <SettingsBox />
                <DealershipBox />
              </>
            )}
          </ParamsContext.Provider>
        </div>
        {/* Right Column */}
        <div className="tw-flex-1 tw-space-y-6 tw-divide-y tw-divide-gray-300 sm:tw-space-y-5">
          <ConfigValidation {...{configJson, params, paramsInitial}} />
          {configJson.exists ? (
            <>
              <VehiclesContext.Provider
                value={{
                  vehicles,
                  vehiclesInitial,
                  setVehicles,
                  handleAddVehicle,
                  handleUpdateVehicle,
                  handleDeleteVehicle,
                  ctaCount,
                  handleCtaCount,
                  hasParamsOrVehiclesChanged,
                  params,
                  paramsInitial,
                }}
              >
                <VehiclesSection />
              </VehiclesContext.Provider>
              <ErrorsSection errors={errors} />
            </>
          ) : null}
          <ButtonsContext.Provider
            value={{
              vehicles,
              params,
              paramsInitial,
              isParamOverridden,
              urlParamsObject,
              hasParamsOrVehiclesChanged,
              hasErrors: !!errors.length,
            }}
          >
            <ButtonsSection />
          </ButtonsContext.Provider>
          {configJson.exists ? (
            <ParamsContext.Provider value={{params, configJson}}>
              <NotesSection />
            </ParamsContext.Provider>
          ) : null}
        </div>
      </main>
      {loadDealerIntegration ? (
        <ParamsContext.Provider value={{paramsInitial, vehicles: vehiclesInitial, configJson}}>
          <DealerIntegration />
        </ParamsContext.Provider>
      ) : null}
    </div>
  )
}

export default TestPage
